<template>
  <div class="w-full" v-if="chat_user" :key="
    chat
      ? 'chat-' + chat.id + '-' + chat.last_message.updated_at
      : 'new-chat-' + chat_user.id
  ">
    <div class="flex w-full" :key="'user-' + chat_user.id" ref="chat-user-details">
      <div class="w-12 h-12">
        <profile-picture :data="chat_user" :sizePx="96" />
      </div>

      <div class="ml-3 w-full" :key="String(chat_user.updated_at)">
        <p class="text-black dark:text-white text-sm sm:text-md font-bold"
          style="display: flex; align-items: center; cursor: pointer"
          @click="$router.push(`/user/${chat_user.username}`)">
          {{ chat_user.first_name }} {{ chat_user.last_name }}
          <span class="ml-1" v-if="chat_user.verified == 1">
            <img src="/static/user_verified.png" style="{ display: flex; height: 16px; width: 16px; }" />
            <!--<font-awesome-icon class="mt-2 mb-0 my-0" icon="check-circle" style="color: #6a71ec; font-size: 16px" />-->
          </span>
        </p>
        <div class="flex justify-between w-full" v-if="chat_user.is_typing === 1 && chat_user.connected === 1">
          <p class="text-gray-700 dark:text-gray-400 text-sm sm:text-md">
            {{ $i18n.t("writing") }}...
          </p>
        </div>
        <div class="flex justify-between w-full pb-2" v-else>
          <p class="text-gray-400 text-sm sm:text-md" v-if="chat_user.connected === 0 && chat_user.last_connection">
            {{ $i18n.t("last_connection") }}:
            {{ chat_user.last_connection | fromNow }}
          </p>
          <p class="text-gray-400 text-sm sm:text-md" v-if="chat_user.connected === 0 && !chat_user.last_connection">
            {{ $i18n.t("never_connected") }}
          </p>
          <p class="text-gray-400 text-sm sm:text-md" v-if="
            (!chat_user.is_typing || chat_user.is_typing) &&
            chat_user.connected === 1
          ">
            {{ $i18n.t("online") }}
          </p>
        </div>
      </div>
    </div>
    <div style="height: 70vh" class="relative flex flex-col content-between">
      <img v-if="messagesLoading" class="w-1/2 md:w-1/6 lg:w-1/5" :src="
        theme === 'dark'
          ? '/static/loading_darkmode.gif'
          : '/static/loading_lightmode.gif'
      " alt="loading-gif" style="
          z-index: 100;
          position: absolute;
          left: 50%;
          top: 50%;
          -webkit-transform: translateX(-50%) translateY(-50%);
          transform: translateX(-50%) translateY(-50%);
        " />
      <div v-if="!messagesLoading" style="height: 90%; overflow-y: auto" ref="chatbox" id="chatbox">
        <message v-for="message in messages" :key="'message-' + message.id" :message="message" @imgLoad="checkImgLoad"
          @openPostReport="openPostReport" @sharePost="sharePost" />
        <div v-if="messages.length === 0" class="flex items-center justify-center h-full">
          <p class="text-black dark:text-white text-center my-auto">
            {{ $i18n.t("no_messages_with") }} {{ chat_user.first_name }}
          </p>
        </div>
      </div>

      <t-input-group :feedback="errors.first('newMessage') ? errors.first('newMessage') : ''"
        :variant="errors.first('newMessage') ? 'danger' : ''" style="position: relative" v-if="!messagesLoading">
        <t-input @click="markAsRead" class="pr-10" @keyup="isTypingDebounced" @keydown="stoppedTypingDebounced"
          name="newMessage" v-model="newMessage" autocomplete="off" ref="chatNewMessage"
          :placeholder="$i18n.t('send_message')" v-on:keyup.enter="sendMessage">
        </t-input>

        <t-loader v-if="newFileLoader" extraClass="text-2xl border-orange-primary"
          style="position: absolute; right: 6%; top: 20%; cursor: pointer" />
        <chat-upload v-show="!newFileLoader" style="position: absolute; right: 4%; top: 5%" @upload="uploadFile"
          ref="chatUpload" />
        <t-loader v-if="newMessageLoader" extraClass="text-2xl border-orange-primary"
          style="position: absolute; right: 2%; top: 20%; cursor: pointer" />
        <img v-if="!newMessageLoader" @click="sendMessage" src="/static/icons/send.svg" alt="Send icon" style="
            position: absolute;
            right: 2%;
            top: 20%;
            cursor: pointer;
            width: 21px;
            filter: invert(78%) sepia(50%) saturate(4740%) hue-rotate(359deg)
              brightness(102%) contrast(108%);
          " />
      </t-input-group>
    </div>
  </div>
  <div v-else class="w-full h-full flex items-center justify-center">
    <p class="text-black dark:text-white">
      {{ $i18n.t("select_a_chat") }}
    </p>
  </div>
</template>

<script>
const notify = () => import("@/utils/notify.js")
import ProfilePicture from "@/components/user/ProfilePicture.vue"
import Message from "@/components/chat/Message.vue"
import { debounce } from "lodash"

export default {
  components: {
    ProfilePicture,
    Message
  },
  props: {},
  computed: {
    chat() {
      return this.$store.getters["chat/getElement"]
    },
    theme() {
      return this.$store.getters["getTheme"]
    },
    loggedUser() {
      return this.$store.getters["auth/getUser"]
    },
    messages() {
      return this.$store.getters["chat/getElementMessages"]
    },
    clearText() {
      return this.$store.getters["chat/getClearText"]
    },
    user() {
      return this.$store.getters["chat/getElementUser"]
    }
  },
  data() {
    return {
      messagesLoading: this.chat ? true : false,
      newMessage: "",
      newMessageLoader: false,
      chat_user: null,
      newFileLoader: false
    }
  },
  methods: {
    openPostReport(post) {
      this.$emit("openPostReport", post)
    },
    sharePost(post) {
      this.$emit("sharePost", post)
    },
    uploadFile(file) {
      let type_file = 4
      if (file.type.includes("image")) {
        type_file = 1
      } else if (file.type.includes("audio")) {
        type_file = 2
      }
      this.newFileLoader = true
      this.$store
        .dispatch("global/create", {
          payload: {
            id_user: this.loggedUser.id,
            id_user_a: this.loggedUser.id,
            id_user_b: this.user.id,
            type_file: type_file,
            message: file.name,
            url_file: file,
            id_chat: this.chat ? this.chat.id : ""
          },
          route: "/chat_message/save",
          module: null
        })
        .then((response) => {
          this.$store.dispatch("chat/clearText", true)
          this.newFileLoader = false
          this.$refs.chatUpload.onClosed()
        })
        .catch((error) => {
          notify().then(({ notification }) => {
            notification("error", error.code, this.$snotify, this.$i18n.locale)
          })
          this.newFileLoader = false
        })
    },
    isTyping() {
      if (this.chat) {
        this.$socket.emit("typing", {
          id_user: this.loggedUser.id,
          id_chat: this.chat ? this.chat.id : ""
        })
      }
    },
    isTypingDebounced() { },
    stoppedTyping() {
      if (this.chat) {
        this.$socket.emit("stop_typing", {
          id_user: this.loggedUser.id,
          id_chat: this.chat ? this.chat.id : ""
        })
      }
    },
    stoppedTypingDebounced() { },
    otherUser(chat) {
      if (!chat) {
        return this.user
      }
      if (parseInt(chat.id_user_a) === this.loggedUser.id) {
        return chat.user_b
      } else {
        return chat.user_a
      }
    },
    sendMessage() {
      if (this.newMessage.trim() === "")
        return this.$snotify.error(
          this.$i18n.locale === "en"
            ? "Message cannot be empty"
            : "El mensaje no puede estar vacío",
          "Error",
          {
            timeout: 5000,
            showProgressBar: true,
            closeOnClick: true,
            pauseOnHover: true,
            titleMaxLength: 40,
            bodyMaxLength: 100,
            position: "rightBottom"
          }
        )
      this.newMessageLoader = true
      if (this.chat && this.chat.id) {
        this.$socket.emit("message", {
          id_user: this.loggedUser.id,
          id_chat: this.chat ? this.chat.id : null,
          message: this.newMessage
        })
        this.newMessageLoader = false
      } else {
        this.newMessageLoader = true
        this.$store
          .dispatch("global/create", {
            payload: {
              id_user: this.loggedUser.id,
              id_user_a: this.loggedUser.id,
              id_user_b: this.user.id,
              type_file: 0,
              message: this.newMessage
            },
            route: "/chat_message/save",
            module: null
          })
          .then((response) => {
            this.$store.dispatch("chat/addChatMessage", response.data)
            this.$store.dispatch("chat/clearText", true)
            this.newMessageLoader = false
          })
          .catch((error) => {
            notify().then(({ notification }) => {
              notification(
                "error",
                error.code,
                this.$snotify,
                this.$i18n.locale
              )
            })
            this.newMessageLoader = false
          })
      }
      this.$nextTick(() => {
        const el = this.$refs['chat-user-details'];
        console.log(el);
        el.scrollTo(
          0,
          el.scrollHeight
        )
      })
    },
    markAsRead() {
      if (this.chat) {
        this.$socket.emit("mark_as_read", {
          id_user: this.loggedUser.id,
          id_chat: this.chat.id
        })
      }
    },
    getMessages() {
      this.messagesLoading = true
      this.$store
        .dispatch("global/getItems", {
          route: "/chat_message/all",
          module: null,
          noPaginate: true,
          params: {
            id_chat: this.chat ? this.chat.id : "",
            id_user_requesting: this.loggedUser.id,
            order_key: "updated_at",
            order_value: "desc",
            active: 1
          }
        })
        .then((response) => {
          let messages = response.data.data ? response.data.data : response.data
          messages = messages.reverse()
          this.$store.dispatch("chat/setElementMessages", messages)
          this.messagesLoading = false
        })
        .catch((error) => {
          this.messagesLoading = false
          notify().then(({ notification }) => {
            notification("error", error.code, this.$snotify, this.$i18n.locale)
          })
        })
    },
    chatboxClicked(event) {
      var container = document.getElementById("chatbox")
      if (container) {
        if (container === event.target || container.contains(event.target)) {
          this.markAsRead()
        }
      }
    },
    checkImgLoad(messageId) {
      let index = this.messages.findIndex((x) => x.id === messageId)
      if (index === this.messages.length - 1) {
        this.$refs.chatbox.scrollTo(0, this.$refs.chatbox.scrollHeight)
      }
    }
  },
  created() {
    this.$store.dispatch("chat/clearText", false)
    if (!this.chat) {
      this.$store.dispatch("chat/setElementMessages", [])
    }
    if (this.chat) this.getMessages()
    this.chat_user = this.otherUser(this.chat)

    window.vueComponent = this

    window.addEventListener("click", window.vueComponent.chatboxClicked)
    this.isTypingDebounced = debounce(this.isTyping, 1000, {
      leading: true,
      trailing: false
    })
    this.stoppedTypingDebounced = debounce(this.stoppedTyping, 1000)
  },
  beforeDestroy() {
    window.removeEventListener("click", window.vueComponent.chatboxClicked)
  },
  unmounted() {
    window.removeEventListener("click", window.vueComponent.chatboxClicked)
  },
  watch: {
    user: {
      handler() {
        this.chat_user = this.otherUser(this.chat)
      },
      deep: true
    },
    chat: {
      handler() {
        if (this.chat) {
          this.getMessages()
          this.newMessage = ""
        } else {
          this.$store.dispatch("chat/setElementMessages", [])
        }
      }
    },
    messages() {
      if (this.chat_user) {
        this.$nextTick(() => {
          if (this.$refs.chatbox) {
            const element = document.getElementById("chatbox")

            const scrolled = element.scrollBottom + element.offsetHeight
            this.$refs.chatbox.scrollTo(0, this.$refs.chatbox.scrollHeight)
          }
          this.$refs.chatNewMessage.focus()
        })
      }
    },
    clearText: {
      handler() {
        if (this.clearText) {
          this.newMessage = ""
          this.$store.dispatch("chat/clearText", false)
        } else {
          this.$nextTick(() => {
            this.$refs.chatNewMessage.focus()
          })
        }
      }
    }
  }
}
</script>
