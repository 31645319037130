<template>
  <div class="full px-2 py-2">
    <div
      class="flex items-center"
      :class="{
        'justify-center': message.type_file === -1,
        'justify-end': message.type_file !== -1 && isOwnMessage,
        'justify-start': message.type_file !== -1 && !isOwnMessage
      }"
    >
      <profile-picture
        :data="message.user"
        :sizePx="48"
        v-if="
          message.type_file !== -1 &&
          !isRandomChat &&
          message.followed_by_you_status === 2 &&
          !isOwnMessage
        "
        class="my-auto mr-1"
      />
      <div
        class="my-2 rounded-2xl px-2 py-2 bg-gray-700"
        :style="
          message.type_file === 1 && message.url_file
            ? 'width: 50%;'
            : 'width: fit-content'
        "
      >
         <p class="text-white mx-1 text-xs" v-if="userIsModerator">
            🛡️Admin
          </p>
        <div
          class="block md:flex justify-between"
          v-if="message.type_file !== -1"
        >
          <p class="text-white font-bold mx-1" v-if="isOwnMessage">
            {{ loggedUserName }}
          </p>
          <p class="text-white font-bold mx-1 text-sm" v-else>
            {{ message.username }}
            {{
              message.followed_by_you_status === 2 &&
              message.username !== message.user.username
                ? `(${message.user.username})`
                : ""
            }}
          </p>
          <div class="flex items-center">
            <p class="text-gray-300 text-xs mx-1">
              {{
                new Intl.DateTimeFormat(navigator_language, {
                  weekday: "long",
                  year: "numeric",
                  month: "long",
                  day: "numeric",
                  hour: "numeric",
                  minute: "numeric"
                }).format(new Date(message.created_at + "Z"))
              }}
            </p>

            <div v-if="!isRandomChat" class="flex justify-end">
              <t-dropdown>
                <div
                  slot="trigger"
                  slot-scope="{
                    mousedownHandler,
                    focusHandler,
                    blurHandler,
                    keydownHandler
                  }"
                  class="flex"
                >
                  <button
                    class="flex items-center justify-end flex-shrink-0 py-1 mx-1 text-black dark:text-white bg-transparent rounded-r"
                    @mousedown="mousedownHandler"
                    @focus="focusHandler"
                    @blur="blurHandler"
                    @keydown="keydownHandler"
                  >
                    <icon
                      icon="ellipsis-v"
                      class="my-auto text-black dark:text-white text-md"
                      style="cursor: pointer"
                    ></icon>
                  </button>
                </div>

                <div slot-scope="{ blurHandler }">
                  <button
                    v-if="!isOwnMessage && isMuted"
                    class="block w-full px-1 py-1 text-sm leading-5 text-black dark:text-white transition duration-150 ease-in-out bg-gray-100 dark:bg-gray-700 dark:hover:bg-gray-800 hover:bg-gray-200 focus:outline-none focus:bg-gray-200 dark:focus:bg-gray-800"
                    role="menuitem"
                    @blur="blurHandler"
                    @click="toggleMute('UNMUTE')"
                  >
                    <icon icon="check" class="text-primary-1100 mr-1" />
                    {{ $i18n.t("unmute") }}
                  </button>

                  <button
                    v-if="!isOwnMessage && !isMuted"
                    class="block w-full px-1 py-1 text-sm leading-5 text-black dark:text-white transition duration-150 ease-in-out bg-gray-100 dark:bg-gray-700 dark:hover:bg-gray-800 hover:bg-gray-200 focus:outline-none focus:bg-gray-200 dark:focus:bg-gray-800"
                    role="menuitem"
                    @blur="blurHandler"
                    @click="toggleMute('MUTE')"
                  >
                    <icon icon="comment-slash" class="text-primary-1100 mr-1" />
                    {{ $i18n.t("mute") }}
                  </button>

                  <button
                    v-if="!isOwnMessage"
                    class="block w-full px-1 py-1 text-sm leading-5 text-black dark:text-white transition duration-150 ease-in-out bg-gray-100 dark:bg-gray-700 dark:hover:bg-gray-800 hover:bg-gray-200 focus:outline-none focus:bg-gray-200 dark:focus:bg-gray-800"
                    role="menuitem"
                    @blur="blurHandler"
                    @click="$emit('startChat', message)"
                  >
                    <img
                      class="mr-1 inline-block primary-color-svg"
                      src="/static/icons/messages.svg"
                      alt="messages icon"
                      style="width: 20px"
                    />
                    {{
                      message.id_chat
                        ? $i18n.t("open_private_chat")
                        : $i18n.t("start_chat")
                    }}
                  </button>

                  <button
                    v-if="!isOwnMessage && canModerate"
                    class="block w-full px-1 py-1 text-sm leading-5 text-black dark:text-white transition duration-150 ease-in-out bg-gray-100 dark:bg-gray-700 dark:hover:bg-gray-800 hover:bg-gray-200 focus:outline-none focus:bg-gray-200 dark:focus:bg-gray-800"
                    role="menuitem"
                    @blur="blurHandler"
                    @click="openBanUser"
                  >
                    <icon
                      icon="exclamation-circle"
                      class="text-primary-1100 mr-1"
                    />
                    {{ $i18n.t("ban_user") }}
                  </button>

                  <button
                    v-if="isOwnMessage"
                    class="block w-full px-1 py-1 text-sm leading-5 text-black dark:text-white transition duration-150 ease-in-out bg-gray-100 dark:bg-gray-700 dark:hover:bg-gray-800 hover:bg-gray-200 focus:outline-none focus:bg-gray-200 dark:focus:bg-gray-800"
                    role="menuitem"
                    @blur="blurHandler"
                    @click="$emit('openChangeUsernameModal')"
                  >
                    {{ $i18n.t("update_username") }}
                  </button>
                </div>
              </t-dropdown>
            </div>
          </div>
        </div>
        <template v-if="message.deleted && message.deleted === 1">
          <p class="text-white py-1 text-sm italic">
            {{ $i18n.t("message_deleted") }}
          </p>
        </template>
        <template v-else>
          <div v-if="message.type_file === -1">
            <template
              v-if="
                messageType === 'received_chat_request' ||
                messageType === 'sent_chat_request'
              "
            >
              <div
                v-if="messageType === 'received_chat_request'"
                :key="`${message.id - message.accepted}`"
              >
                <p class="text-white py-1 text-xs">
                  {{ message.username }} {{ $i18n.t("wants_to_chat_with_you") }}
                </p>
                <div class="flex justify-center items-center gap-2 my-2">
                  <t-button
                    variant="gray"
                    type="button"
                    @click="answerChatRequest('REJECT')"
                    class="mx-1"
                    >{{ $i18n.t("reject") }}</t-button
                  >
                  <t-button
                    variant="orange"
                    type="button"
                    @click="answerChatRequest('ACCEPT')"
                    class="mx-1"
                    >{{ $i18n.t("accept") }}</t-button
                  >
                </div>
              </div>
              <p v-else class="text-white py-1 text-xs">
                {{ message.username }} {{ $i18n.t("sent_a_chat_request_to") }}
                {{ message.other_username }}
              </p>
            </template>
            <template v-else>
              <p class="text-white py-1 text-xs">
                {{
                  messageType === "joined"
                    ? `${messageUsername(message)} ${$i18n.t(
                        "joined_public_room"
                      )}`
                    : ""
                }}
                {{
                  messageType === "left"
                    ? `${messageUsername(message)} ${$i18n.t(
                        "left_public_room"
                      )}`
                    : ""
                }}
                {{
                  messageType === "received_friend_request"
                    ? `${messageUsername(message)} ${$i18n.t(
                        "sent_you_friend_request"
                      )}`
                    : ""
                }}
                {{
                  messageType === "sent_friend_request"
                    ? `${messageUsername(message)} ${$i18n.t(
                        "sent_friend_request"
                      )} ${message.other_username}`
                    : ""
                }}
                {{
                  messageType === "changed_username"
                    ? `${messageUsername(message)} ${$i18n.t(
                        "changed_username"
                      )} ${message.new_username}`
                    : ""
                }}
                {{
                  messageType === "banned"
                    ? `${messageUsername(message)} ${$i18n.t("was_banned")}`
                    : ""
                }}
                {{
                  messageType === "banned_by_admin"
                    ? `${messageUsername(message)} ${$i18n.t(
                        "was_banned"
                      )} ${$i18n.t("by_moderator")}`
                    : ""
                }}
              </p>
            </template>

            <div
              v-if="messageType === 'received_friend_request'"
              :key="`${message.id - message.accepted}`"
            >
              <template
                v-if="
                  message.followed_by_you_status !== 2 ||
                  message.follows_you_status !== 2
                "
              >
                <div class="flex w-full items-center py-2">
                  <div class="w-8 h-8">
                    <profile-picture :data="message.user" :sizePx="96" />
                  </div>

                  <div class="ml-3 w-full">
                    <p
                      class="text-black dark:text-white text-sm sm:text-md font-bold"
                    >
                      {{ message.user.username }}
                    </p>
                    <p
                      class="text-black dark:text-white text-sm sm:text-md font-bold"
                    >
                      {{ message.user.first_name }} {{ message.user.last_name }}
                    </p>
                  </div>
                </div>
                <div class="flex justify-center items-center gap-2 my-2">
                  <t-button
                    variant="gray"
                    type="button"
                    @click="answerFriendRequest('REJECT')"
                    class="mx-1"
                    >{{ $i18n.t("reject") }}</t-button
                  >
                  <t-button
                    variant="orange"
                    type="button"
                    @click="answerFriendRequest('ACCEPT')"
                    class="mx-1"
                    >{{ $i18n.t("accept") }}</t-button
                  >
                </div>
              </template>
            </div>
          </div>
          <div v-if="!message.type_file || message.type_file === 0">
            <p class="text-white py-1 text-sm" v-if="seeMessage">
              {{ message.message }}
            </p>
            <div class="flex flex-col items-center justify-center" v-else>
              <icon
                icon="exclamation-circle"
                class="text-orange-primary mx-auto"
              />
              <p class="text-white py-1 text-sm">
                {{ $i18n.t("view_message_with_forbidden_words_1") }}
              </p>
              <t-button
                variant="orange"
                type="button"
                @click="seeMessage = true"
                class="mx-auto"
                >{{ $i18n.t("see_anyway") }}</t-button
              >
            </div>
          </div>

          <audio
            class="mx-auto"
            controls
            v-if="message.type_file === 2 && message.url_file"
          >
            <source :src="message.url_file" type="audio/mp4" />
          </audio>
          <template v-if="message.type_file === 1 && message.url_file">
            <div class="relative">
              <img
                :src="message.url_file"
                alt="Image"
                style="cursor: pointer; max-height: 50vh"
                @click="showModal = true"
                class="mx-auto my-auto"
                :class="!seePicture ? 'filter blur-xl' : ''"
                @load="$emit('imgLoad', message.id)"
              />
              <div
                v-if="!seePicture"
                style="
                  position: absolute;
                  left: 50%;
                  top: 50%;
                  -webkit-transform: translateX(-50%) translateY(-50%);
                  transform: translateX(-50%) translateY(-50%);
                "
              >
                <div class="flex flex-col items-center justify-center">
                  <icon
                    icon="exclamation-circle"
                    class="text-orange-primary mx-auto"
                  />
                  <p class="text-white py-1 text-sm">
                    {{ $i18n.t("view_unfollowed_user_image_1") }}
                  </p>
                  <t-button
                    variant="orange"
                    type="button"
                    @click="seePicture = true"
                    class="mx-auto"
                    >{{ $i18n.t("see_anyway") }}</t-button
                  >
                </div>
              </div>
            </div>
          </template>
        </template>
      </div>

      <vue-ellipse-progress
        class="ml-1"
        v-if="message.type_file === -1 && timeLeft > 0"
        :progress="progress"
        color="#ffa500"
        emptyColor="#734a00"
        :legend="false"
        :size="20"
        reverse
      />

      <img
        v-if="
          message.type_file !== -1 &&
          !isRandomChat &&
          !isOwnMessage &&
          message.followed_by_you_status !== 2
        "
        src="/static/icons/follow.svg"
        alt="Add user"
        class="ml-2 my-auto cursor-pointer dark:filter invert"
        style="height: 25px; width: auto"
        @click="openSendFriendRequestModal"
      />
    </div>
    <t-modal
      header=""
      :value="showModal"
      @closed="showModal = false"
      variant="3xl"
    >
      <template v-if="message.type_file === 1 && message.url_file">
        <img :src="message.url_file" alt="Full image" class="mx-auto my-auto" />
      </template>
      <template v-slot:footer>
        <div class="flex justify-end">
          <t-button
            variant="outline-gray-sm"
            type="button"
            @click="showModal = false"
            class="mx-1"
            >{{ $i18n.t("close") }}</t-button
          >
        </div>
      </template>
    </t-modal>
  </div>
</template>

<script>
import ProfilePicture from "@/components/user/ProfilePicture.vue"
import { VueEllipseProgress } from "vue-ellipse-progress"

export default {
  components: {
    ProfilePicture,
    VueEllipseProgress
  },
  props: {
    message: {
      type: Object,
      default: () => {}
    },
    isRandomChat: {
      type: Boolean,
      required: true
    },
    loggedUserName: {
      type: String,
      required: true
    },
    canModerate: {
      type: Boolean,
      default: false
    },
    userIsModerator: {
      type: Boolean,
      default: false
    }
  },
  computed: {
    loggedUser() {
      return this.$store.getters["auth/getUser"]
    },
    isOwnMessage() {
      return this.message.id_user === this.loggedUser.id
    },
    isMuted() {
      return (
        this.$store.getters["public_room/getMutedUsers"].findIndex(
          (x) =>
            x.id_user === this.message.id_user &&
            x.id_public_room === this.message.id_public_room
        ) !== -1
      )
    },
    messageType() {
      if (this.message.message === "joined") return "joined"
      if (this.message.message === "left") return "left"
      if (this.message.message === "banned") return "banned"
      if (this.message.message === "banned by admin") return "banned_by_admin"
      if (
        this.message.message.includes("friend request") &&
        this.message.id_message_reply === this.loggedUser.id
      )
        return "received_friend_request"
      if (
        this.message.message.includes("friend request") &&
        this.message.id_message_reply !== this.loggedUser.id
      )
        return "sent_friend_request"
      if (this.message.new_username) return "changed_username"
      if (
        this.message.message.includes("chat request") &&
        this.message.id_message_reply === this.loggedUser.id
      )
        return "received_chat_request"
      if (
        this.message.message.includes("chat request") &&
        this.message.id_message_reply !== this.loggedUser.id
      )
        return "sent_chat_request"
    }
  },
  data() {
    return {
      navigator_language: navigator.language,
      host: window.location.host,
      showModal: false,
      secondsPerMessage: 60,
      timeLeft: 60 * 2, // Twice as much time as secondsPerMessage because interval executes each 500 ms
      progress: this.message.type_file === -1 ? 100 : 0,
      intervalID: null,
      seePicture: false,
      seeMessage: true
    }
  },
  methods: {
    openBanUser() {
      this.$emit("openBanUser", this.message)
    },
    answerChatRequest(action) {
      this.$emit("answerChatRequest", {
        id_user_sent: this.message.id_user,
        id_user_received: this.loggedUser.id,
        status_request: action === "ACCEPT" ? 2 : 3,
        id_message: this.message.id
      })
    },
    answerFriendRequest(action) {
      this.$emit("answerFriendRequest", {
        status_request: action === "ACCEPT" ? 2 : 3,
        message: this.message
      })
    },
    messageUsername(message) {
      return `${message.username} ${
        message.followed_by_you_status === 2
          ? "(" + message.user.username + ")"
          : ""
      }`
    },
    toggleMute(action) {
      this.$emit("toggleMute", {
        action,
        id_user: this.message.id_user,
        username:
          this.message.followed_by_you_status === 2
            ? `${this.message.username} (${this.message.user.username})`
            : this.message.username
      })
    },
    openSendFriendRequestModal() {
      this.$emit("openSendFriendRequestModal", this.message)
    },
    openLink(url) {
      const newWindow = window.open(`${url}`, "_blank", "noopener,noreferrer")
      if (newWindow) newWindow.opener = null
    },
    handleProgress() {
      if (this.timeLeft <= 0) clearInterval(this.intervalID)
      this.timeLeft = parseInt(this.timeLeft) - 1
      this.progress = (this.timeLeft * 100) / 2 / this.secondsPerMessage
    }
  },
  created() {
    if (this.message.type_file === -1) {
      const messageDate = new Date(this.message.created_at + "Z")
      const dateDiff = (new Date().getTime() - messageDate.getTime()) / 1000
      this.timeLeft = (this.secondsPerMessage - dateDiff) * 2
      this.progress = (this.timeLeft * 100) / 2 / this.secondsPerMessage
      this.intervalID = setInterval(this.handleProgress, 500)
    }

    this.seeMessage =
      this.message.forbidden_words && !this.isOwnMessage ? false : true

    this.seePicture =
      this.isOwnMessage || this.message.followed_by_you_status === 2
        ? true
        : false
  },
  watch: {
    timeLeft() {
      if (this.timeLeft <= 0) {
        this.$store.dispatch(
          "public_room/removeExpiredMessage",
          this.message.id
        )
      }
    },
    message: {
      handler() {
        this.seeMessage =
          this.message.forbidden_words && !this.isOwnMessage ? false : true

        this.seePicture =
          this.isOwnMessage || this.message.followed_by_you_status === 2
            ? true
            : false
      },
      deep: true
    }
  }
}
</script>
