<template>
  <div v-if="!random_chat">
    <div v-if="randomChatLoading" class="flex justify-center items-center">
      <div class="w-2/3 flex flex-col justify-center items-center">
        <img
          class="w-1/2 my-2"
          :src="
            theme === 'dark'
              ? '/static/loading_darkmode.gif'
              : '/static/loading_lightmode.gif'
          "
          alt="random-chat-loading"
        />
        <p class="text-black dark:text-white my-1">
          {{ $i18n.t("random_chat_waiting") }}
        </p>

        <t-button type="button" @click="abandonChat" class="mt-2"
          >{{ $i18n.t("cancel") }}
        </t-button>
      </div>
    </div>
  </div>
  <div v-else>
    <div class="w-full" :key="`random_chat-${random_chat}`">
      <div
        class="flex w-full"
        :key="'random_chat-' + random_chat"
        ref="random_chat-details"
        style="cursor: pointer"
      >
        <div class="ml-3 w-full">
          <div class="flex justify-between items-center">
            <div class="flex items-center gap-x-2" :key="chat_user.updated_at">
              <div
                class="w-12 h-12"
                v-if="chat_user.user.followed_by_you_status === 2"
              >
                <profile-picture :data="chat_user.user" :sizePx="96" />
              </div>
              <div>
                <p
                  class="text-black dark:text-white text-sm sm:text-md font-bold"
                  v-if="chat_user"
                >
                  {{ chat_user.username }}
                  {{
                    chat_user.user.followed_by_you_status === 2 &&
                    chat_user.username !== chat_user.user.username
                      ? `(${chat_user.user.username})`
                      : ""
                  }}
                </p>
                <p class="text-black dark:text-white text-sm sm:text-md">
                  {{ $i18n.t("random_chats") }}
                </p>
              </div>
            </div>
            <div class="flex gap-2">
              <img
                v-if="chat_user.user.followed_by_you_status !== 2"
                src="/static/icons/follow.svg"
                alt="Add user"
                class="my-auto cursor-pointer dark:filter invert"
                style="height: 25px; width: auto"
                @click="openSendFriendRequestModal"
              />
              <img
                src="/static/icons/random_chat.svg"
                alt="Next chat"
                class="my-auto cursor-pointer dark:filter invert"
                style="height: 30px; width: auto"
                @click="nextChatConfirmation = true"
              />
            </div>
          </div>
        </div>
      </div>
      <div style="height: 70vh" class="relative flex flex-col content-between">
        <div style="height: 90%; overflow-y: auto" ref="chatbox" id="chatbox">
          <public-message
            v-for="message in messages"
            :key="'message-' + message.created_at"
            :showAddUser="false"
            :showOptions="false"
            :isRandomChat="true"
            :message="message"
            :loggedUserName="$i18n.t('you')"
            @sharePost="sharePost"
          />
          <div
            v-if="messages.length === 0"
            class="flex items-center justify-center h-full"
          >
            <p class="text-black dark:text-white text-center my-auto">
              {{ $i18n.t("no_messages") }}
            </p>
          </div>
        </div>

        <t-input-group
          :feedback="
            errors.first('newMessage') ? errors.first('newMessage') : ''
          "
          :variant="errors.first('newMessage') ? 'danger' : ''"
          style="position: relative"
        >
          <t-input
            class="pr-10"
            name="newMessage"
            ref="randomChatNewMessage"
            v-model="newMessage"
            :placeholder="$i18n.t('send_message')"
            autocomplete="off"
            v-on:keyup.enter="sendMessage"
          >
          </t-input>

          <t-loader
            v-if="newFileLoader"
            extraClass="text-2xl border-orange-primary"
            style="position: absolute; right: 6%; top: 30%; cursor: pointer"
          />
          <chat-upload
            :allowFiles="false"
            v-show="!newFileLoader"
            style="position: absolute; right: 4%; top: 5%"
            @upload="uploadFile"
            ref="chatUpload"
          />

          <t-loader
            v-if="newMessageLoader"
            extraClass="text-2xl border-orange-primary"
            style="position: absolute; right: 2%; top: 30%; cursor: pointer"
          />
          <img
            v-if="!newMessageLoader"
            @click="sendMessage"
            src="/static/icons/send.svg"
            alt="Send icon"
            style="
              position: absolute;
              right: 2%;
              top: 30%;
              cursor: pointer;
              width: 21px;
              filter: invert(78%) sepia(50%) saturate(4740%) hue-rotate(359deg)
                brightness(102%) contrast(108%);
            "
          />
        </t-input-group>
      </div>
    </div>

    <t-modal
      header=""
      :value="nextChatConfirmation"
      @closed="closeModal('nextChatConfirmation')"
      variant="lg"
      :clickToClose="false"
    >
      <div class="my-auto">
        <p class="text-black dark:text-white my-1 font-bold">
          {{ $i18n.t("next_chat_confirmation") }}
        </p>
      </div>

      <template v-slot:footer>
        <div class="flex justify-end">
          <t-button
            variant="gray"
            @click="closeModal('nextChatConfirmation')"
            type="button"
            class="mx-1"
            >{{ $i18n.t("no") }}
          </t-button>
          <t-button type="button" @click="goToNextChat" class="mx-1"
            >{{ $i18n.t("yes") }}
          </t-button>
        </div>
      </template>
    </t-modal>

    <t-modal
      header=""
      :value="sendFriendRequestModal"
      @closed="closeModal('sendFriendRequestModal')"
      variant="lg"
    >
      <div class="my-auto">
        <p class="text-black dark:text-white my-1 font-bold">
          {{ $i18n.t("send_friend_request_confirmation_1") }}
          <span class="text-orange-primary"
            >@{{ chat_user ? chat_user.username : "" }}</span
          >?
        </p>

        <p class="text-black dark:text-white my-1">
          {{ $i18n.t("send_friend_request_confirmation_2") }}
        </p>
      </div>

      <template v-slot:footer>
        <div class="flex justify-end">
          <t-button
            variant="gray"
            @click="closeModal('sendFriendRequestModal')"
            type="button"
            class="mx-1"
            >{{ $i18n.t("no") }}
          </t-button>
          <t-button type="button" @click="sendFriendRequest" class="mx-1"
            >{{ $i18n.t("yes") }}
          </t-button>
        </div>
      </template>
    </t-modal>

    <t-modal
      header=""
      :value="receivedFriendRequestModal"
      @closed="closeModal('receivedFriendRequestModal')"
      variant="lg"
    >
      <div class="my-auto">
        <p class="text-black dark:text-white my-1">
          <span class="text-orange-primary font-bold"
            >@{{
              friendRequestSender ? friendRequestSender.username : ""
            }}</span
          >
          {{ $i18n.t("received_friend_request_confirmation") }}
        </p>
      </div>

      <template v-slot:footer>
        <div class="flex justify-end">
          <t-button
            variant="gray"
            @click="answerFriendRequest(3)"
            type="button"
            class="mx-1"
            >{{ $i18n.t("no") }}
          </t-button>
          <t-button type="button" @click="answerFriendRequest(2)" class="mx-1"
            >{{ $i18n.t("yes") }}
          </t-button>
        </div>
      </template>
    </t-modal>
  </div>
</template>

<script>
import ProfilePicture from "@/components/user/ProfilePicture.vue"
import PublicMessage from "@/components/chat/PublicMessage.vue"

export default {
  components: {
    ProfilePicture,
    PublicMessage
  },
  props: {},
  computed: {
    random_chat() {
      return this.$store.getters["random_chat/getElement"]
    },
    chat_user() {
      return this.$store.getters["random_chat/getElementUser"]
    },
    theme() {
      return this.$store.getters["getTheme"]
    },
    loggedUser() {
      return this.$store.getters["auth/getUser"]
    },
    messages() {
      return this.$store.getters["random_chat/getElementMessages"]
    },
    clearText() {
      return this.$store.getters["random_chat/getClearText"]
    },
    newFriendRequest() {
      return this.$store.getters["getNewFriendRequest"]
    },
    friendRequestSender() {
      return this.$store.getters["getFriendRequestSender"]
    }
  },
  data() {
    return {
      newMessage: "",
      newMessageLoader: false,
      newFileLoader: false,
      sendFriendRequestModal: false,
      receivedFriendRequestModal: false,
      randomChatLoading: true,
      nextChatConfirmation: false
    }
  },
  methods: {
    uploadFile(file) {
      let type_file = 4
      if (file.type.includes("image")) {
        type_file = 1
      } else if (file.type.includes("audio")) {
        type_file = 2
      }
      this.newFileLoader = true
      this.$store
        .dispatch("global/create", {
          payload: {
            id_user: this.loggedUser.id,
            id_random_chat: this.random_chat,
            type_file: type_file,
            message: file.name,
            url_file: file
          },
          route: "/user_random_chat/save_message",
          module: null
        })
        .then((response) => {
          this.$store.dispatch("public_room/clearText", true)
          this.newFileLoader = false
          this.$refs.chatUpload.onClosed()
        })
        .catch((error) => {
          notify().then(({ notification }) => {
            notification("error", error.code, this.$snotify, this.$i18n.locale)
          })
          this.newFileLoader = false
        })
    },
    goToNextChat() {
      this.$socket.emit("new_random_chat", {
        id_user: this.loggedUser.id,
        id_user_chatted: this.chat_user.user.id
      })

      this.$store.dispatch("random_chat/setElement", null)
      this.$store.dispatch("random_chat/setElementUser", null)
      this.$store.dispatch("random_chat/setElementMessages", [])

      this.closeModal("nextChatConfirmation")
    },
    answerFriendRequest(status_request) {
      this.$socket.emit("answer_follow_request", {
        id_user_sent: this.friendRequestSender.id,
        id_user_received: this.loggedUser.id,
        id_random_chat: this.random_chat,
        status_request
      })
    },
    sendFriendRequest() {
      this.$socket.emit("send_follow_request", {
        id_user_sent: this.loggedUser.id,
        id_user_received: this.chat_user.user.id,
        id_random_chat: this.random_chat
      })
      this.closeModal("sendFriendRequestModal")
    },
    openSendFriendRequestModal() {
      this.sendFriendRequestModal = true
    },
    closeModal(modalName) {
      if (modalName === "receivedFriendRequestModal") {
        if (this.newFriendRequest) {
          this.$store.dispatch("setNewFriendRequest", {
            status: false,
            user_sent: null
          })
        }
      }
      this[modalName] = false
    },
    sharePost(post) {
      this.$emit("sharePost", post)
    },
    abandonChat() {
      this.$socket.emit("leave_random_chats")
      this.$emit("abandonedChat")
    },
    sendMessage() {
      if (this.newMessage.trim() === "")
        return this.$snotify.error(
          this.$i18n.locale === "en"
            ? "Message cannot be empty"
            : "El mensaje no puede estar vacío",
          "Error",
          {
            timeout: 5000,
            showProgressBar: true,
            closeOnClick: true,
            pauseOnHover: true,
            titleMaxLength: 40,
            bodyMaxLength: 100,
            position: "rightBottom"
          }
        )

      this.$socket.emit("message", {
        id_user: this.loggedUser.id,
        id_random_chat: this.random_chat,
        message: this.newMessage
      })
    }
  },
  created() {},
  watch: {
    random_chat: {
      handler() {
        if (this.random_chat) {
          this.randomChatLoading = false
        } else {
          this.randomChatLoading = true
        }
      },
      deep: true
    },
    messages() {
      if (this.random_chat) {
        this.$nextTick(() => {
          if (this.$refs.chatbox) {
            const element = document.getElementById("chatbox")

            const scrolled = element.scrollTop + element.offsetHeight

            if (scrolled / element.scrollHeight > 0.9)
              this.$refs.chatbox.scrollTo(0, this.$refs.chatbox.scrollHeight)
          }

          this.$refs.randomChatNewMessage.focus()
        })
      }
    },
    clearText() {
      if (this.clearText) {
        this.newMessage = ""
        this.$store.dispatch("random_chat/clearText", false)
      } else {
        this.$nextTick(() => {
          this.$refs.randomChatNewMessage.focus()
        })
      }
    },
    newFriendRequest() {
      if (this.newFriendRequest) {
        this.receivedFriendRequestModal = true
      } else {
        this.receivedFriendRequestModal = false
      }
    }
  },
  destroyed() {
    if (this.random_chat) {
      this.$socket.emit("leave_random_chats")
      this.$emit("abandonedChat")
    }
  },
  unmounted() {
    if (this.random_chat) {
      this.$socket.emit("leave_random_chats")
      this.$emit("abandonedChat")
    }
  }
}
</script>
