<template>
  <div class="full px-2 py-2">
    <div v-if="message.post && message.post.type === 2" :class="{
      'w-1/2': message.post.active === 1,
      'w-fit h-fit': message.post.active !== 1,
      'ml-auto': message.id_user === loggedUser.id,
      'mr-auto': message.id_user !== loggedUser.id
    }" :style="message.post.active === 1 ? 'aspect-ratio: 16/9' : ''">
      <template v-if="message.post.active === 1">
        <post-mode-element :element="message.post" :user="{
          ...message.post.user,
          followed_by_you_status: message.post.followed_by_you_status
        }" :canEdit="false" :ref="'message-post-' + message.post.id" :allowComments="false" :allowSubscription="false"
          @likedPost="likedPost" @openPostReport="openPostReport" @sharePost="sharePost" />
        <p class="-mt-3 text-primary-700 pb-1 text-sm text-center font-bold cursor-pointer hover:underline"
          @click="$router.push(`/post/${message.post.id}`)" v-if="
            message.post.id_country === 0 ||
            message.post.id_country === loggedUser.id_country
          ">
          {{ $i18n.t("see_post") }}
        </p>
      </template>
      <template v-else>
        <p class="text-primary-700 pb-1 text-sm text-center font-bold select-none">
          {{ $i18n.t("post_unavailable") }}
        </p>
      </template>
    </div>

    <div v-if="message.story" :class="{
      'w-1/2': message.story.active === 1,
      'w-fit h-fit': message.story.active !== 1,
      'ml-auto': message.id_user === loggedUser.id,
      'mr-auto': message.id_user !== loggedUser.id
    }" :style="message.story.active === 1 ? 'aspect-ratio: 16/9' : ''">
      <template v-if="message.story.active === 1 && message.story.imgkit_url_story">
        <div class="my-5">
          <t-card :variant="theme === 'dark' ? 'dark' : 'light'" class="py-4">
            <div class="pb-2 px-6">
              <div class="w-full flex justify-between">
                <div class="flex">
                  <div class="w-16 h-16">
                    <profile-picture :data="message.story.user" :sizePx="128" />
                  </div>

                  <div class="ml-3 my-auto">
                    <p class="text-black dark:text-white text-sm sm:text-md font-bold" @click="openProfile"
                      style="display: flex; align-items: center; cursor: pointer">
                      {{ message.story.user.username }}
                      <span class="ml-1" v-if="message.story.user.verified == 1">
                        <img src="/static/user_verified.png" style="{ display: flex; height: 16px; width: 16px; }" />
                        <!--<font-awesome-icon class="mt-2 mb-0 my-0" icon="check-circle" style="color: #6a71ec; font-size: 16px" />-->
                      </span>
                    </p>
                    <p v-if="message.story && message.story.created_at"
                      class="text-black dark:text-white text-sm sm:text-md">
                      {{ message.story.created_at | fromNow }}
                    </p>
                  </div>
                </div>
              </div>
            </div>

            <img v-if="message.story.type === 1" class="mx-auto px-2 py-2" :src="
              theme === 'dark'
                ? '/static/loading_darkmode.gif'
                : '/static/loading_lightmode.gif'
            " alt="story-loader" ref="story-loader" />
            <img v-if="message.story.type === 1" :src="picture(message.story)" :ref="'story-' + message.story.id"
              alt="Story" @load="onImageLoad" class="hidden mx-auto px-2 py-2" :style="{ 'max-height': '300px' }">

            <video v-if="message.story.type === 2" class="mx-auto px-2 py-1"
              :src="message.story.imgkit_url_story || message.story.url_story" :style="'max-height: 80vh'"
              @play="(evt) => handleVideoPlay(message.story.id, evt.target)" playsinline controls loop muted
              controlsList="nodownload"></video>
          </t-card>
        </div>

        <!--<p
          class="-mt-3 text-primary-700 pb-1 text-sm text-center font-bold cursor-pointer hover:underline"
          @click="openStory(message.story)"
        >
          {{ $i18n.t("see_story") }}
        </p>-->
      </template>
      <template v-else>
        <p class="text-primary-700 pb-1 text-sm text-center font-bold select-none">
          {{ $i18n.t("story_unavailable") }}
        </p>
      </template>
    </div>

    <div class="my-2 rounded-2xl px-2 py-2" :class="
      message.id_user === loggedUser.id
        ? 'ml-auto bg-gray-700'
        : 'bg-gray-700 mr-auto'
    " :style="
  (message.type_file === 1 && message.url_file) || message.post || message.story
    ? 'width: 60%;'
    : 'width: fit-content'
">
      <div class="w-full block md:flex justify-between">
        <p class="text-white font-bold mx-1" style="display: flex; align-items: center; width: fit-content"
          v-if="message.id_user === loggedUser.id">
          {{ $i18n.t("you") }}
          <span class="ml-1" v-if="message.user.verified == 1">
            <img src="/static/user_verified.png" style="{ display: flex; height: 16px; width: 16px; }" />
            <!--<font-awesome-icon class="mt-2 mb-0 my-0" icon="check-circle" style="color: #6a71ec; font-size: 16px" />-->
          </span>
        </p>
        <p class="text-white font-bold mx-1 text-sm" v-else style="display: flex; align-items: center;">
          {{ message.user.first_name }} {{ message.user.last_name }}
          <span class="ml-1" v-if="message.user.verified == 1">
            <img src="/static/user_verified.png" style="{ display: flex; height: 16px; width: 16px; }" />
            <!--<font-awesome-icon class="mt-2 mb-0 my-0" icon="check-circle" style="color: #6a71ec; font-size: 16px" />-->
          </span>
        </p>
      </div>
      <template v-if="message.deleted && message.deleted === 1">
        <p class="text-white py-1 text-sm italic">
          {{ $i18n.t("message_deleted") }}
        </p>
      </template>
      <template v-else>
        <div v-if="!message.type_file || message.type_file === 0">
          <p class="text-white py-1 text-sm">
            {{ message.message }}
          </p>
          <p class="w-full text-gray-300 text-xs pr-2" style="text-align: right">
            {{
                new Intl.DateTimeFormat(navigator_language, {
                  weekday: "long",
                  year: "numeric",
                  month: "long",
                  day: "numeric",
                  hour: "numeric",
                  minute: "numeric"
                }).format(new Date(message.created_at + "Z"))
            }}
          </p>
        </div>

        <audio class="mx-auto" controls v-if="message.type_file === 2 && message.url_file">
          <source :src="message.url_file" type="audio/mp4" />
        </audio>
        <img v-if="message.type_file === 1 && message.url_file" :src="message.url_file" alt="Image"
          style="cursor: pointer; max-height: 50vh" @click="showModal = true" class="mx-auto my-auto"
          @load="$emit('imgLoad', message.id)" />
        <div class="flex flex-row items-center justify-center w-fit mx-auto"
          v-if="message.type_file === 4 && message.url_file" style="cursor: pointer"
          @click="openLink(message.url_file)">
          <icon icon="file-contract" class="text-primary-800 text-3xl mx-2" />
          <p class="text-white py-1 text-sm">
            {{ message.message }}
          </p>
        </div>
      </template>
    </div>

    <t-modal header="" :value="showModal" @closed="showModal = false" variant="3xl">
      <template v-if="message.type_file === 1 && message.url_file">
        <img :src="message.url_file" alt="Full image" class="mx-auto my-auto" />
      </template>
      <template v-slot:footer>
        <div class="flex justify-end">
          <t-button variant="outline-gray-sm" type="button" @click="showModal = false" class="mx-1">{{ $i18n.t("close")
          }}</t-button>
        </div>
      </template>
    </t-modal>

    <stories :show.sync="storyModal" @openProfile="openProfile" />
  </div>
</template>

<script>

import ProfilePicture from "@/components/user/ProfilePicture.vue"
import PostModeElement from "@/components/gallery/PostModeElement.vue"
import Stories from "@/components/story/Stories.vue"

export default {
  components: {
    PostModeElement,
    Stories,
    ProfilePicture
  },
  props: {
    message: {
      type: Object,
      default: () => { }
    }
  },
  computed: {
    loggedUser() {
      return this.$store.getters["auth/getUser"]
    },
    storiesUser() {
      return this.$store.getters["story/getItemUser"]
    },
    theme() {
      return this.$store.getters["getTheme"]
    },
  },
  data() {
    return {
      navigator_language: navigator.language,
      host: window.location.host,
      showModal: false,
      storyModal: false,
    }
  },
  mounted() {
    console.log(this.message)
  },
  methods: {
    handleVideoPlay(storyId, htmlElement) {
      if (!htmlElement.classList.contains("viewed")) {
        htmlElement.classList.add("viewed")
        this.$store
          .dispatch("global/update", {
            payload: {
              id: storyId,
              new_view: 1
            },
            route: "/story/update",
            module: null
          })
          .catch((error) => {
            notify().then(({ notification }) => {
              notification(
                "error",
                error.code,
                this.$snotify,
                this.$i18n.locale
              )
            })
          })
      }
    },
    likedPost(post) {
      const idx = this.post.map(p => p.id).indexOf(post.id)
      this.post[idx] = post
    },
    openStory(data) {
      this.$emit("openStory", data)
    },
    openProfile(user) {
      this.showModal = false
      this.storyModal = false
      this.$store.dispatch("setSearchModal", false)

      if (this.loggedUser.id === user.id) {
        this.$router.push("/profile")
      } else {
        this.$router.push(`/user/${user.username}`)
      }
    },
    openPostReport(post) {
      this.$emit("openPostReport", post)
    },
    sharePost(post) {
      this.$emit("sharePost", post)
    },
    onImageLoad() {
      if (this.$refs["story-loader"])
        this.$refs["story-loader"].classList.add("hidden")

      if (this.$refs["story-" + this.message.story.id] && this.$refs["story-" + this.message.story.id].classList)
        this.$refs["story-" + this.message.story.id].classList.remove("hidden")

      this.$socket.emit("story_viewed", {
        id_user: this.loggedUser.id,
        id_story: this.message.story.id
      })
    },
    picture(element) {
      if (element.type == 2 && element.imgkit_url_story) {
        return element.imgkit_url_story + '/ik-thumbnail.jpg?tr=so-2'
      }
      else {
        return element.imgkit_url_story
          ? element.imgkit_url_story
          : element.url_story
      }
    },
    openLink(url) {
      const newWindow = window.open(`${url}`, "_blank", "noopener,noreferrer")
      if (newWindow) newWindow.opener = null
    },
    getYoutubeEmbedLink(link) {
      let base_url = "https://www.youtube.com/embed/"
      if (link.includes("youtube")) {
        return (
          base_url +
          link.split("?v=")[1] +
          `?feature=oembed&enablejsapi=1&Origin=${window.location.host}`
        )
      } else if (link.includes("youtu.be")) {
        return (
          base_url +
          link.split("/")[3] +
          `?feature=oembed&enablejsapi=1&Origin=${window.location.host}`
        )
      } else {
        return ""
      }
    }
  }
}
</script>
