<template>
  <div class="w-full">
    <div
      class="my-1 w-full"
      v-for="user in users"
      :key="'new-chat-preview-' + user.id"
      style="border-bottom: 1px solid #4a4a4a"
    >
      <div
        class="w-full flex justify-between"
        @click="createChat(user)"
        style="cursor: pointer"
      >
        <div class="flex w-full">
          <div class="w-16 h-16">
            <profile-picture v-if="user" :data="user" :sizePx="96" />
          </div>

          <div class="ml-3 w-full">
            <p class="text-black dark:text-white text-sm font-bold">
              {{ user.first_name }} {{ user.last_name }}
            </p>
            <p class="text-black dark:text-white text-sm" style="display: flex; align-items: center;">
              {{ user.username }}
              <span class="ml-1" v-if="user.verified == 1">
                <img src="/static/user_verified.png" style="{ display: flex; height: 16px; width: 16px; }" />
                <!--<font-awesome-icon class="mt-2 mb-0 my-0" icon="check-circle" style="color: #6a71ec; font-size: 16px" />-->
              </span>
            </p>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
const notify = () => import("@/utils/notify.js")
import ProfilePicture from "@/components/user/ProfilePicture.vue"

export default {
  components: {
    ProfilePicture
  },
  props: {
    users: {
      type: Array,
      default: () => []
    }
  },
  computed: {
    loggedUser() {
      return this.$store.getters["auth/getUser"]
    }
  },
  data() {
    return {}
  },
  methods: {
    createChat(user) {
      this.$emit("chatCreation", user)
    }
  },
  created() {}
}
</script>
