<template>
  <div class="my-8 mx-2 w-full">
    <ul>
      <li
        v-for="(tab, index) in tabs"
        :key="tab.title"
        class="pt-4 mr-4 inline-block cursor-pointer font-normal"
        :class="[
          index == selectedIndex
            ? 'border-b-4 border-gray-800 text-gray-800 color-gray-800 dark:border-white dark:color-white dark:text-white'
            : 'text-gray-800 dark:text-white'
        ]"
        @click="selectTab(index)"
      >
        {{ tab.title }}
      </li>
    </ul>
    <slot></slot>
  </div>
</template>

<script>
export default {
  data() {
    return {
      selectedIndex: 0,
      tabs: []
    }
  },
  created() {
    this.tabs = this.$children
  },
  mounted() {
    this.selectTab(0)
  },
  methods: {
    selectTab(i) {
      this.selectedIndex = i

      this.tabs.forEach((tab, index) => {
        tab.isActive = index === i
      })
    }
  }
}
</script>
