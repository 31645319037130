<template>
  <div class="w-full">
    <div
      class="my-1 w-full"
      v-for="room in rooms"
      :key="`room-preview-${room.id}-${
        room.last_message ? room.last_message.updated_at : ''
      }`"
      style="border-bottom: 1px solid #4a4a4a"
    >
      <div
        class="w-full flex justify-between"
        @click="selectChat(room)"
        style="cursor: pointer"
      >
        <div class="flex w-full" v-if="room.name">
          <div class="w-16 h-16">
            <profile-picture :data="room" :sizePx="96" />
          </div>

          <div class="ml-3 w-full" :key="String(room.updated_at)">
            <p
              class="text-black dark:text-white text-sm"
              :class="
                room.last_message && room.last_message.unread === 1
                  ? 'font-bold'
                  : ''
              "
            >
              {{ room.name }}
            </p>
            <div class="flex justify-between w-full">
              <div v-if="room.is_typing === 1 && room.user_writing">
                <p class="text-black dark:text-white text-sm font-bold">
                  {{ room.user_writing }} {{ $i18n.t("is_writing") }}...
                </p>
              </div>
              <div class="flex" v-else-if="room.last_message">
                <p
                  class="text-black dark:text-white text-sm"
                  :class="
                    room.last_message.id_user !== loggedUser.id &&
                    room.last_message.unread === 1
                      ? 'font-bold'
                      : ''
                  "
                >
                  {{
                    room.last_message.message.length > 10
                      ? room.last_message.message.slice(0, 10) + "..."
                      : room.last_message.message
                  }}
                </p>
                <p class="text-gray-400 text-sm">
                  - {{ room.last_message.created_at | fromNow }}
                </p>
              </div>
              <div
                v-if="room.unread_messages"
                class="w-7 h-7 bg-primary-1100 rounded-full flex justify-center items-center"
              >
                <p class="text-white text-xs">
                  {{ calculateHighNumber(room.unread_messages) }}
                </p>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
const notify = () => import("@/utils/notify.js")
import ProfilePicture from "@/components/user/ProfilePicture.vue"

export default {
  components: {
    ProfilePicture
  },
  props: {},
  computed: {
    rooms() {
      return this.$store.getters["room/getAllUnpaginated"].map((el) => ({
        ...el.room,
        silenced: el.silenced,
        unread_messages: el.unread_messages
      }))
    },
    loggedUser() {
      return this.$store.getters["auth/getUser"]
    }
  },
  data() {
    return {}
  },
  methods: {
    selectChat(room) {
      this.$emit("chatSelection", { chat: room, isRoom: true })
    },
    calculateHighNumber(number) {
      if (isNaN(number)) return 0
      if (Number(number) > 1000000) return (number / 1000000).toFixed(1) + "M"
      if (Number(number) > 1000) {
        return (number / 1000).toFixed(1) + "K"
      } else {
        return number
      }
    }
  },
  created() {}
}
</script>
