<template>
  <div class="block xl:flex">
    <div class="w-full xl:w-1/3 px-1">
      <div style="height: 70vh; overflow-y: auto" class="w-full relative">
        <div class="w-full px-2 flex justify-end py-4">
          <t-button
            type="button"
            class="mx-1"
            @click="enterUsernameModal = true"
          >
            <span v-if="!user_random_chat" class="text-black dark:text-white">{{
              $i18n.t("join_random_chats")
            }}</span>
            <span v-else class="text-black dark:text-white">{{
              $i18n.t("update_username")
            }}</span>
            <img
              src="/static/icons/random_chat.svg"
              alt="New random chat button"
              width="25.75px"
              class="ml-2 my-auto dark:filter invert"
          /></t-button>
        </div>
        <template v-if="publicChats.length > 0">
          <public-chat-list @chatSelection="selectChat" />
        </template>

        <template v-else>
          <p class="text-black dark:text-white text-center mt-5">
            {{ $i18n.t("no_chats") }}
          </p>
        </template>
      </div>
    </div>
    <div class="w-full xl:w-2/3 px-5">
      <public-room-chat
        v-if="isPublicRoom"
        @abandonedChat="abandonedChat('public_room')"
        @openPostReport="openPostReport"
        @sharePost="sharePost"
        @startRandomChat="startRandomChat"
      />
      <random-chat
        v-if="isRandomChat"
        @abandonedChat="abandonedChat('random_chat')"
        @openPostReport="openPostReport"
        @sharePost="sharePost"
      />
    </div>

    <t-modal
      header=""
      :value="enterUsernameModal"
      @closed="closeModal('enterUsernameModal')"
      variant="lg"
      :clickToClose="false"
    >
      <div class="my-auto">
        <p class="text-orange-primary my-1 font-bold">
          Jevi {{ $i18n.t("random_chats") }}
        </p>

        <template v-if="user_random_chat">
          <p class="text-black dark:text-white my-2">
            {{ $i18n.t("random_chats_message_5") }}
          </p>

          <t-input-group
            :feedback="errors.first('username') ? errors.first('username') : ''"
            :variant="errors.first('username') ? 'danger' : ''"
          >
            <t-input
              v-validate="'username'"
              data-vv-validate-on="input"
              name="username"
              v-model="username"
              :placeholder="$i18n.t('username')"
              autocomplete="off"
            >
            </t-input>
          </t-input-group>
        </template>
        <template v-else-if="step === 1">
          <p class="text-black dark:text-white my-2">
            {{ $i18n.t("random_chats_message_1") }}
          </p>

          <p class="text-black dark:text-white my-2">
            {{ $i18n.t("random_chats_message_2") }}
          </p>

          <p class="text-black dark:text-white my-2">
            {{ $i18n.t("random_chats_message_3") }}
          </p>
        </template>
        <template v-else-if="step === 2">
          <p class="text-black dark:text-white my-2">
            {{ $i18n.t("random_chats_message_4") }}
          </p>

          <p class="text-black dark:text-white my-2">
            {{ $i18n.t("continue_without_username") }}
          </p>

          <t-input-group
            :feedback="errors.first('username') ? errors.first('username') : ''"
            :variant="errors.first('username') ? 'danger' : ''"
          >
            <t-input
              v-validate="'username'"
              data-vv-validate-on="input"
              name="username"
              v-model="username"
              :placeholder="$i18n.t('username')"
              autocomplete="off"
            >
            </t-input>
          </t-input-group>
        </template>
      </div>

      <template v-slot:footer>
        <div class="flex justify-end">
          <t-button
            :disabled="errors.first('username') ? true : false"
            v-if="user_random_chat"
            type="button"
            @click="updateUserRandomChat"
            class="mx-1"
            >{{ $i18n.t("update") }}
          </t-button>
          <t-button
            v-else-if="step === 1"
            type="button"
            @click="step = 2"
            class="mx-1"
            >{{ $i18n.t("continue") }}
          </t-button>
          <t-button
            :disabled="errors.first('username') ? true : false"
            v-else-if="step === 2"
            type="button"
            @click="joinRandomChats"
            class="mx-1"
            >{{ $i18n.t("begin_chatting") }}
          </t-button>
        </div>
      </template>
    </t-modal>

    <t-modal
      header=""
      :value="bannedFromPublicRoomModal"
      @closed="closeModal('bannedFromPublicRoomModal')"
      variant="lg"
      :clickToClose="false"
    >
      <div class="my-auto" v-if="banned_date">
        <p class="text-black dark:text-white text-sm">
          {{ $i18n.t("suspended_from_public_room") }}:
          <span class="text-black dark:text-white font-bold">
            {{
              new Intl.DateTimeFormat($i18n.locale, {
                weekday: "long",
                year: "numeric",
                month: "long",
                day: "numeric",
                hour: "numeric",
                minute: "numeric"
              }).format(new Date(banned_date + "Z"))
            }}
          </span>
        </p>
      </div>

      <template v-slot:footer>
        <div class="flex justify-end">
          <t-button
            type="button"
            @click="bannedFromPublicRoomModal = false"
            class="mx-1"
            >{{ $i18n.t("i_understand") }}
          </t-button>
        </div>
      </template>
    </t-modal>
  </div>
</template>

<script>
import PublicChatList from "@/components/chat/PublicChatList"
import PublicRoomChat from "@/components/chat/PublicRoomChat"
import RandomChat from "@/components/chat/RandomChat"
const notify = () => import("@/utils/notify.js")
export default {
  data() {
    return {
      isPublicRoom: false,
      isRandomChat: false,
      enterUsernameModal: false,
      username: "",
      step: 1,
      bannedFromPublicRoomModal: false,
      banned_date: null
    }
  },
  components: {
    PublicChatList,
    PublicRoomChat,
    RandomChat
  },
  computed: {
    loggedUser() {
      return this.$store.getters["auth/getUser"]
    },
    publicChat() {
      return this.$store.getters["public_room/getElement"]
    },
    publicChats() {
      return this.$store.getters["public_room/getAllUnpaginated"]
    },
    user_random_chat() {
      return this.$store.getters["random_chat/getUserRandomChat"]
    }
  },
  methods: {
    startRandomChat() {
      this.selectChat({ isRandomChat: true })
      this.$store.dispatch("public_room/setElement", null)
    },
    updateUserRandomChat() {
      this.$store
        .dispatch("global/update", {
          payload: {
            id: this.user_random_chat.id,
            username: this.username ? this.username : this.loggedUser.username
          },
          route: "/user_random_chat/update"
        })
        .then((response) => {
          notify().then(({ notification }) => {
            notification(
              "master-update",
              response.code,
              this.$snotify,
              this.$i18n.locale
            )
          })
          if (response.code === 200) this.closeModal("enterUsernameModal")
        })
        .catch((error) => {
          notify().then(({ notification }) => {
            notification("error", error.code, this.$snotify, this.$i18n.locale)
          })
        })
    },
    closeModal(modalName) {
      if (modalName === "enterUsernameModal") {
        this.username = ""
        this.step = 1
      }
      this[modalName] = false
    },
    joinRandomChats() {
      this.$socket.emit("join_random_chats", {
        id_user: this.loggedUser.id,
        username:
          this.username !== "" ? this.username : this.loggedUser.username
      })
      this.closeModal("enterUsernameModal")
      this.selectChat({ isRandomChat: true })
    },
    openPostReport(post) {
      this.$emit("openPostReport", post)
    },
    sharePost(post) {
      this.$emit("sharePost", post)
    },
    selectActiveType(type) {
      if (type === "publicChat") {
        this.isPublicRoom = true
        this.isRandomChat = false
      }
      if (type === "random_chat") {
        this.isRandomChat = true
        this.isPublicRoom = false
      }
    },
    selectChat({ chat, isPublicRoom, isRandomChat }) {
      if (isPublicRoom) {
        if (new Date() < new Date(chat.banned_until + "Z")) {
          this.bannedFromPublicRoomModal = true
          this.banned_date = chat.banned_until
          return
        }
        this.selectActiveType("publicChat")
        this.$store.dispatch("public_room/setElement", chat)
        this.$store.dispatch("public_room/setUserPublicRoom", null)
      } else if (isRandomChat) {
        this.selectActiveType("random_chat")
      }
    },
    abandonedChat(type) {
      this.isPublicRoom = false
      this.isRandomChat = false
      if (type === "public_room") {
        this.$store.dispatch("public_room/setElement", null)
        this.$store.dispatch("public_room/setUserPublicRoom", null)
        this.getPublicRooms()
      }
    },
    getPublicRooms() {
      this.$store
        .dispatch("global/getItems", {
          route: "/public_room/all",
          module: "public_room",
          noPaginate: true,
          params: {
            id_country: this.loggedUser.id_country,
            order_key: "ordering",
            order_value: "asc",
            active: 1
          }
        })
        .then(() => {
          this.$emit("finishLoading")
        })
        .catch((error) => {
          this.$emit("finishLoading")
          notify().then(({ notification }) => {
            notification("error", error.code, this.$snotify, this.$i18n.locale)
          })
        })
    }
  },

  created() {
    this.$store.dispatch("random_chat/setElement", null)
    this.$store.dispatch("random_chat/setElementUser", null)
    this.$store.dispatch("random_chat/setUserRandomChat", null)
    this.$store.dispatch("random_chat/setElementMessages", [])
    if (this.publicChat) {
      this.$socket.emit("leave_public_room", {
        id_user: this.loggedUser.id,
        id_public_room: this.publicChat.id
      })
      this.$store.dispatch("public_room/setElement", null)
      this.$store.dispatch("public_room/setUserPublicRoom", null)
    }
    this.$emit("startLoading")
    this.getPublicRooms()
  },
  watch: {}
}
</script>
