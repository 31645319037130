<template>
  <div class="block xl:flex">
    <div class="w-full xl:w-1/3 px-1">
      <tabs class="text-center" ref="chatTabs">
        <tab :title="$i18n.t('personal')">
          <div style="height: 60vh; overflow-y: auto" v-if="!showNewChat" class="w-full relative pt-16">
            <div class="absolute top-0 right-0 pt-3">
              <t-button type="button" @click="showNewChat = true" style="height: fit-content">
                {{ $i18n.t("new_chat") }}
                <img src="/static/icons/new_chat.svg" alt="Add chat icon" class="ml-1 dark:filter invert w-5 mx-auto" />
                <!--<icon style="width: 20px; height: 20px" class="ml-1 text-white" icon="plus" />-->
              </t-button>
            </div>

            <template v-if="chats.length > 0">
              <chat-list-preview @chatSelection="selectChat" @chatDeleted="getChats" />
            </template>

            <template v-else>
              <p class="text-black dark:text-white text-center mt-5">
                {{ $i18n.t("no_chats") }}
              </p>
            </template>
          </div>
          <div v-else class="mx-auto block items-center justify-center">
            <div class="flex justify-start pt-3 pb-4">
              <t-button type="button" @click="showNewChat = false" style="height: fit-content">
                <icon class="mr-2" icon="angle-left" />{{
                    $i18n.t("return")
                }}
              </t-button>
              <p class="text-black dark:text-white my-auto w-full ml-1">
                {{ $i18n.t("new_chat") }}
              </p>
            </div>

            <div style="height: 55vh; overflow-y: auto" v-if="users.length > 0" class="w-full">
              <new-chat-preview :users="users" @chatCreation="createChat" />
            </div>
          </div>
        </tab>
        <tab :title="$i18n.t('communities')">
          <div style="height: 60vh; overflow-y: auto" class="w-full relative pt-16">
            <template v-if="rooms.length > 0">
              <room-list-preview @chatSelection="selectChat" />
            </template>

            <template v-else>
              <p class="text-black dark:text-white text-center mt-5">
                {{ $i18n.t("no_communities") }}
              </p>
            </template>
          </div>
        </tab>
        <tab :title="$i18n.t('station')" v-if="stationRoom && currentRadio">
          <div style="height: 60vh; overflow-y: auto" class="w-full relative">
            <p class="text-black dark:text-white text-center my-5">
              {{ $i18n.t("listening_to") }}
            </p>
            <div class="flex justify-start">
              <img :src="currentRadio.logo" alt="Logo de la radio" class="object-cover mx-3"
                style="aspect-ratio: 16/9; height: 3.5rem" />
              <div class="mx-3">
                <p class="text-black dark:text-white text-sm md:text-md font-bold">
                  {{ currentRadio.name }}
                </p>
                <p class="text-black dark:text-white text-sm">
                  {{
                      currentRadio.description.length > 50
                        ? currentRadio.description.slice(0, 50) + "..."
                        : currentRadio.description
                  }}
                </p>
              </div>
            </div>
            <t-button v-if="!isStation" type="button" class="mt-3 mx-auto" @click="selectActiveType('station')"
              style="height: fit-content">
              {{ $i18n.t("enter_chat") }}</t-button>
          </div>
        </tab>
      </tabs>
    </div>
    <div class="w-full xl:w-2/3 px-5">
      <chat v-if="isChat" @openPostReport="openPostReport" @sharePost="sharePost" />
      <room-chat v-if="isRoom" @abandonedChat="abandonedChat('room')" @openPostReport="openPostReport"
        @sharePost="sharePost" />
      <station-chat v-if="isStation" />
    </div>
  </div>
</template>

<script>
import Tab from "@/components/Tab"
import Tabs from "@/components/Tabs"
import ChatListPreview from "@/components/chat/ChatListPreview"
import RoomListPreview from "@/components/chat/RoomListPreview"
import NewChatPreview from "@/components/chat/NewChatPreview"
import Chat from "@/components/chat/Chat"
import RoomChat from "@/components/chat/RoomChat"
import StationChat from "@/components/chat/StationChat"
const notify = () => import("@/utils/notify.js")
export default {
  data() {
    return {
      showNewChat: false,
      isChat: false,
      isRoom: false,
      isStation: false
    }
  },
  components: {
    Tab,
    Tabs,
    ChatListPreview,
    RoomListPreview,
    Chat,
    RoomChat,
    NewChatPreview,
    StationChat
  },
  computed: {
    loggedUser() {
      return this.$store.getters["auth/getUser"]
    },
    users() {
      return this.$store.getters["user/getAllUnpaginated"]
    },
    chats() {
      return this.$store.getters["chat/getAllUnpaginated"]
    },
    rooms() {
      return this.$store.getters["room/getAllUnpaginated"].map((el) => ({
        ...el.room,
        silenced: el.silenced
      }))
    },
    stationRoom() {
      return this.$store.getters["stationRoom/getElement"]
    },
    currentRadio() {
      return this.$store.getters["radio/getItem"]
    }
  },
  methods: {
    openPostReport(post) {
      this.$emit("openPostReport", post)
    },
    sharePost(post) {
      this.$emit("sharePost", post)
    },
    selectActiveType(type) {
      if (type === "chat") {
        this.isRoom = false
        this.isStation = false
        this.isChat = true
      }
      if (type === "room") {
        this.isRoom = true
        this.isStation = false
        this.isChat = false
      }
      if (type === "station") {
        this.isRoom = false
        this.isStation = true
        this.isChat = false
      }
    },
    createChat(user) {
      let existing_chat = this.chats.find(
        (x) => x.id_user_a === user.id || x.id_user_b === user.id
      )
      if (existing_chat) {
        this.selectChat({ chat: existing_chat, user })
        this.showNewChat = false
      } else {
        this.$store.dispatch("chat/setElement", null)
        this.$store.dispatch("chat/setElementUser", user)
        this.showNewChat = false
        this.selectActiveType("chat")
      }
    },
    selectChat({ chat, user, isRoom }) {
      if (isRoom) {
        this.selectActiveType("room")
        this.$store.dispatch("room/setElement", chat)
      } else {
        this.selectActiveType("chat")
        this.$store.dispatch("chat/setElement", chat)
        this.$store.dispatch("chat/setElementUser", user)
      }
    },
    abandonedChat(type) {
      this.isChat = false
      this.isRoom = false
      this.isStation = false
      if (type === "room") {
        this.$store.dispatch("room/setElement", null)
        this.getRooms()
      }
    },
    getChats() {
      this.$store
        .dispatch("global/getItems", {
          route: "/chat/all",
          module: "chat",
          noPaginate: true,
          params: {
            id_user: this.loggedUser.id,
            chat_available: 1,
            order_key: "updated_at",
            order_value: "desc",
            active: 1
          }
        })
        .then(() => {
          this.$emit("finishLoading")
        })
        .catch((error) => {
          this.$emit("finishLoading")
          notify().then(({ notification }) => {
            notification("error", error.code, this.$snotify, this.$i18n.locale)
          })
        })
    },
    getRooms() {
      this.$store
        .dispatch("global/getItems", {
          route: "/room_user/all",
          module: "room",
          noPaginate: true,
          params: {
            id_user: this.loggedUser.id,
            chat_available: 1,
            order_key: "updated_at",
            order_value: "desc",
            active: 1
          }
        })
        .then(() => { })
        .catch((error) => {
          notify().then(({ notification }) => {
            notification("error", error.code, this.$snotify, this.$i18n.locale)
          })
        })
    },
    getUsers() {
      this.$store
        .dispatch("global/getItems", {
          route: "/follow_request/complete_list",
          module: null,
          noPaginate: true,
          params: {
            id_user: this.loggedUser.id,
            active: 1
          }
        })
        .then((response) => {
          let users = response.data.data ? response.data.data : response.data
          users = users.map((x) => x.user)
          this.$store.dispatch("user/setUsers", users)
        })
        .catch((error) => {
          notify().then(({ notification }) => {
            notification("error", error.code, this.$snotify, this.$i18n.locale)
          })
        })
    }
  },

  created() {
    if (this.room) this.$store.dispatch("chat/setElement", null)
    this.$store.dispatch("room/setElement", null)

    this.$store.dispatch("chat/setElementUser", null)
    this.$emit("startLoading")
    this.getChats()
    this.getRooms()
    this.getUsers()
  },
  mounted() {
    // Query param 't': Tab
    this.$nextTick(() => {
      if (
        this.$route.query.t === "sr" &&
        this.stationRoom &&
        this.currentRadio
      ) {
        this.$refs["chatTabs"].selectTab(2)
        this.selectActiveType("station")
      }
    })
  },
  watch: {
    chats() {
      if (this.chats.length) {
        this.$nextTick(() => {
          if (this.$route.query.ch_id) {
            let existing_chat = this.chats.find(
              (x) => x.id === Number(this.$route.query.ch_id)
            )
            if (existing_chat) {
              const other_user =
                existing_chat.id_user_a === this.loggedUser.id
                  ? existing_chat.user_b
                  : existing_chat.user_a
              this.selectChat({ chat: existing_chat, user: other_user })
            }
          }
        })
      }
    },
    stationRoom() {
      if (!this.stationRoom) {
        this.isRoom = false
        this.isStation = false
        this.isChat = false
        this.$refs["chatTabs"].selectTab(0)
      }
    }
  }
}
</script>
