<template>
  <div class="w-full">
    <div
      class="py-1 w-full"
      v-for="chat in chats"
      :key="'chat-preview-' + chat.id + '-' + chat.last_message.updated_at"
      style="border-bottom: 1px solid #4a4a4a"
    >
      <div
        class="w-full flex justify-between"
      >
        <div class="flex w-full align-items-center my-auto" v-if="otherUser(chat)">
          <div @click="selectChat(chat)" style="cursor: pointer" class="w-14 h-14 my-auto pt-1 xl:pt-2">
            <profile-picture
              :data="otherUser(chat)"
              :connectionStatus="otherUser(chat).connected"
              :sizePx="96"
            />
          </div>

          <div @click="selectChat(chat)" style="cursor: pointer" class="ml-3 w-full align-items-center my-auto" :key="String(otherUser(chat).updated_at)">
            <p
              class="text-black dark:text-white text-sm"
              :class="chat.last_message.unread === 1 ? 'font-bold' : ''"
              style="display: flex; align-items: center"
            >
              {{ otherUser(chat) ? otherUser(chat)["first_name"] : "" }}
              {{ otherUser(chat) ? otherUser(chat)["last_name"] : "" }}
              <span class="ml-1" v-if="otherUser(chat) && otherUser(chat).verified == 1">
                <img src="/static/user_verified.png" style="{ display: flex; height: 16px; width: 16px; }" />
                <!--<font-awesome-icon class="mt-2 mb-0 my-0" icon="check-circle" style="color: #6a71ec; font-size: 16px" />-->
              </span>
            </p>
            
            <div class="flex justify-between">
              <div
                v-if="
                  otherUser(chat).is_typing === 1 &&
                  otherUser(chat).connected === 1
                "
              >
                <p class="text-black dark:text-white text-sm font-bold">
                  {{ $i18n.t("writing") }}...
                </p>
              </div>
              <div class="flex" v-else-if="chat.last_message">
                <p
                  class="text-black dark:text-white text-sm"
                  :class="
                    chat.last_message.id_user !== loggedUser.id &&
                    chat.last_message.unread === 1
                      ? 'font-bold'
                      : ''
                  "
                >
                  <span v-if="chat.last_message.id_user === loggedUser.id" class="text-black font-semibold dark:text-white text-sm">
                    {{ $i18n.t('you') + ": " }}
                  </span>
                  <span class="text-black dark:text-white text-sm">
                    {{
                      chat.last_message.message.length > 10
                        ? chat.last_message.message.slice(0, 10) + "..."
                        : chat.last_message.message
                    }}
                  </span>
                </p>
                <p class="text-gray-400 text-sm ml-1">
                  - {{ chat.last_message.created_at | fromNow }}
                </p>
              </div>
            </div>
          </div>

          <div
            class="w-10 h-7 block justify-center items-center"
          >
            <div
              @click="setChat(chat)"
              style="cursor: pointer"
              class="py-2 mb-1 bg-primary-1100 rounded-full flex justify-center items-center"
            >
              <font-awesome-icon class="my-0 py-0 text-white text-xs" icon="trash-alt" />
            </div>

            <div
              v-if="chat.unread_messages"
              class="py-1 bg-primary-1100 rounded-full flex justify-center items-center"
            >
              <p class="text-white text-xs">
                {{ calculateHighNumber(chat.unread_messages) }}
              </p>
            </div>
          </div>
        </div>
      </div>
    </div>

    <t-modal
      header=""
      :value="deleteChat"
      @closed="deleteChat = false"
      variant="md"
    >
      <div class="my-auto">
        <p class="text-black dark:text-white my-1 font-bold">
          {{ $i18n.t("delete_chat_confirmation_1") }}
        </p>

        <p class="text-black dark:text-white my-1">
          {{ $i18n.t("delete_chat_confirmation_2") }}
        </p>
      </div>

      <template v-slot:footer>
        <div class="flex justify-end">
          <t-button
            variant="gray"
            @click="deleteChat = false"
            type="button"
            class="mx-1"
            >{{ $i18n.t("no") }}
          </t-button>
          <t-button type="button" @click="DeleteChat()" class="mx-1">
            <span style="color: #FFFFFF" v-if="!loading">
              {{ $i18n.t("yes") }}
            </span>

            <t-loader
              v-if="loading"
              extraClass="text-2xl border-orange-primary"
            />
          </t-button>
        </div>
      </template>
    </t-modal>
  </div>
</template>

<script>
const notify = () => import("@/utils/notify.js")
import ProfilePicture from "@/components/user/ProfilePicture.vue"

export default {
  components: {
    ProfilePicture
  },
  props: {},
  computed: {
    chats() {
      return this.$store.getters["chat/getAllUnpaginated"]
    },
    loggedUser() {
      return this.$store.getters["auth/getUser"]
    }
  },
  data() {
    return {
      loading: false,
      deleteChat: false,
      selectedChat: null
    }
  },
  methods: {
    otherUser(chat) {
      if (!chat) return {}
      if (parseInt(chat.id_user_a) === this.loggedUser.id) {
        return chat.user_b
      } else {
        return chat.user_a
      }
    },
    selectChat(chat) {
      this.$emit("chatSelection", { chat, user: this.otherUser(chat) })
    },
    setChat(chat) {
      this.selectedChat = chat
      this.deleteChat = true
    },
    DeleteChat() {
      this.loading = true
      
      this.$store
        .dispatch("global/update", {
          payload: {
            id: this.selectedChat.id,
            id_user: this.loggedUser.id,
            delete: 1
          },
          route: "/chat/update",
          module: null
        })
        .then((response) => {
          this.loading = false
          this.deleteChat = false
          this.$store.dispatch("chat/setElement", null)
          this.$store.dispatch("chat/setElementUser", null)
          this.$emit('chatDeleted')

          notify().then(({ notification }) => {
            notification(
              "chat_delete",
              response.code,
              this.$snotify,
              this.$i18n.locale
            )
          })
        })
        .catch((error) => {
          this.loading = false
          notify().then(({ notification }) => {
            notification("error", error.code, this.$snotify, this.$i18n.locale)
          })
        })
    },
    calculateHighNumber(number) {
      if (isNaN(number)) return 0
      if (Number(number) > 1000000) return (number / 1000000).toFixed(1) + "M"
      if (Number(number) > 1000) {
        return (number / 1000).toFixed(1) + "K"
      } else {
        return number
      }
    }
  },
  created() {}
}
</script>
