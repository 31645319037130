<template>
  <div class="w-full">
    <div
      class="my-1 w-full"
      v-for="room in rooms"
      :key="'public-room-list-' + room.id + '-' + room.users_connected"
      style="border-bottom: 1px solid #4a4a4a"
    >
      <div
        class="w-full flex justify-between"
        @click="selectChat(room)"
        style="cursor: pointer"
      >
        <div class="w-full relative" :key="String(room.updated_at)">
          <img
            :src="room.url_banner || '/static/banner-placeholder.jpg'"
            alt="Live chat image"
            class="w-full object-cover rounded-md"
            style="aspect-ratio: 15/3"
          />
          <div class="absolute top-2 left-2">
            <p
              class="text-orange-primary text-sm font-bold"
              style="filter: drop-shadow(0 0 2px #000)"
            >
              {{ room.users_connected }} {{ $i18n.t("online") }}
            </p>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
const notify = () => import("@/utils/notify.js")
import ProfilePicture from "@/components/user/ProfilePicture.vue"

export default {
  components: {
    ProfilePicture
  },
  props: {},
  computed: {
    rooms() {
      return this.$store.getters["public_room/getAllUnpaginated"]
    },
    loggedUser() {
      return this.$store.getters["auth/getUser"]
    },
    publicChat() {
      return this.$store.getters["public_room/getElement"]
    }
  },
  data() {
    return {}
  },
  methods: {
    selectChat(room) {
      if (this.publicChat && this.publicChat.id !== room.id)
        this.$socket.emit("leave_public_room", {
          id_user: this.loggedUser.id,
          id_public_room: this.publicChat.id
        })
      this.$emit("chatSelection", { chat: room, isPublicRoom: true })
    },
    calculateHighNumber(number) {
      if (isNaN(number)) return 0
      if (Number(number) > 1000000) return (number / 1000000).toFixed(1) + "M"
      if (Number(number) > 1000) {
        return (number / 1000).toFixed(1) + "K"
      } else {
        return number
      }
    }
  },
  created() {}
}
</script>
