<template>
  <div v-if="!room">
    <p>{{ $i18n.t("select_a_community") }}</p>
  </div>
  <div v-else>
    <div
      class="w-full"
      v-if="room && !showDetails"
      :key="`room-${room.id}-${room.users_connected}`"
    >
      <div
        class="flex w-full"
        :key="'room-' + room.id"
        ref="room-user-details"
        style="cursor: pointer"
        @click="showDetails = true"
      >
        <div class="w-12 h-12">
          <profile-picture :data="room" :sizePx="96" fieldName="url_picture" />
        </div>

        <div class="ml-3 w-full">
          <p class="text-black dark:text-white text-sm sm:text-md font-bold">
            {{
              room.type === 1
                ? `Near me - ${room.country[$i18n.locale + "_name"]}`
                : room.name
            }}
          </p>
        </div>
      </div>
      <div style="height: 70vh" class="relative flex flex-col content-between">
        <img
          v-if="messagesLoading"
          class="w-1/2 md:w-1/6 lg:w-1/5"
          :src="
            theme === 'dark'
              ? '/static/loading_darkmode.gif'
              : '/static/loading_lightmode.gif'
          "
          alt="loading-gif"
          style="
            z-index: 100;
            position: absolute;
            left: 50%;
            top: 50%;
            -webkit-transform: translateX(-50%) translateY(-50%);
            transform: translateX(-50%) translateY(-50%);
          "
        />
        <div
          v-if="!messagesLoading"
          style="height: 90%; overflow-y: auto"
          ref="chatbox"
          id="chatbox"
        >
          <public-message
            v-for="message in messages"
            :key="'message-' + message.id"
            :message="message"
            :isRandomChat="false"
            :loggedUserName="
              userPublicRoom ? userPublicRoom.username : $i18n.t('you')
            "
            :canModerate="room.id_user_admin === loggedUser.id"
            :userIsModerator="room.id_user_admin === message.id_user"
            @openSendFriendRequestModal="openSendFriendRequestModal"
            @openPostReport="openPostReport"
            @sharePost="sharePost"
            @toggleMute="toggleMute"
            @openChangeUsernameModal="changeUsernameModal = true"
            @answerFriendRequest="answerFriendRequest"
            @startChat="startChat"
            @answerChatRequest="answerChatRequest"
            @openBanUser="openBanUser"
          />
          <div
            v-if="messages.length === 0"
            class="flex items-center justify-center h-full"
          >
            <p class="text-black dark:text-white text-center my-auto">
              {{ $i18n.t("no_messages") }}
            </p>
          </div>
        </div>

        <div class="w-full px-3 py-2 flex" v-if="!messagesLoading && isBanned">
          <p class="text-black dark:text-white text-sm">
            {{ $i18n.t("suspended_from_public_room") }}:
            <span class="text-black dark:text-white font-bold">
              {{
                new Intl.DateTimeFormat($i18n.locale, {
                  weekday: "long",
                  year: "numeric",
                  month: "long",
                  day: "numeric",
                  hour: "numeric",
                  minute: "numeric"
                }).format(new Date(userPublicRoom.banned_until + "Z"))
              }}
            </span>
          </p>
        </div>

        <t-input-group
          :feedback="
            errors.first('newMessage') ? errors.first('newMessage') : ''
          "
          :variant="errors.first('newMessage') ? 'danger' : ''"
          style="position: relative"
          v-else-if="!messagesLoading"
        >
          <t-input
            class="pr-10"
            name="newMessage"
            ref="roomNewMessage"
            v-model="newMessage"
            :placeholder="$i18n.t('send_message')"
            autocomplete="off"
            v-on:keyup.enter="sendMessage"
          >
          </t-input>
          <t-loader
            v-if="newFileLoader"
            extraClass="text-2xl border-orange-primary"
            style="position: absolute; right: 6%; top: 30%; cursor: pointer"
          />
          <chat-upload
            :allowFiles="false"
            v-show="!newFileLoader"
            style="position: absolute; right: 4%; top: 5%"
            @upload="uploadFile"
            ref="chatUpload"
          />

          <t-loader
            v-if="newMessageLoader"
            extraClass="text-2xl border-orange-primary"
            style="position: absolute; right: 2%; top: 30%; cursor: pointer"
          />
          <img
            v-if="!newMessageLoader"
            @click="sendMessage"
            src="/static/icons/send.svg"
            alt="Send icon"
            style="
              position: absolute;
              right: 2%;
              top: 30%;
              cursor: pointer;
              width: 21px;
              filter: invert(78%) sepia(50%) saturate(4740%) hue-rotate(359deg)
                brightness(102%) contrast(108%);
            "
          />
        </t-input-group>
      </div>
    </div>
    <div v-else-if="showDetails" style="height: 85vh; overflow-y: auto">
      <div class="flex justify-start pt-3 pb-4">
        <t-button
          type="button"
          @click="showDetails = false"
          style="height: fit-content"
        >
          <icon class="mr-2" icon="angle-left" />{{
            $i18n.t("return")
          }}</t-button
        >
      </div>

      <div class="px-3">
        <div class="w-1/3 mx-auto">
          <profile-picture :data="room" :sizePx="96" />
        </div>
        <div class="mx-auto my-4">
          <p
            class="text-center text-black dark:text-white text-md sm:text-xl font-bold"
          >
            {{
              room.type === 1
                ? `Near me - ${room.country[$i18n.locale + "_name"]}`
                : room.name
            }}
          </p>
          <p class="text-center text-black dark:text-white text-sm sm:text-md">
            {{ $i18n.t("members") }}: {{ roomUsersAmount }}
          </p>
        </div>

        <p class="text-center text-black dark:text-white text-sm sm:text-md">
          {{ $i18n.t("muted_users") }}: {{ mutedUsers.length }}
        </p>

        <div
          class="my-1 w-full"
          v-for="data in mutedUsers"
          :key="'muted-user-' + data.id_user"
          style="border-bottom: 1px solid #4a4a4a"
        >
          <div class="w-full flex justify-between">
            <div class="flex">
              <p class="text-black dark:text-white text-sm font-bold">
                {{ data.username }}
              </p>
            </div>
            <t-button
              @click="toggleMute({ ...data, action: 'UNMUTE' })"
              variant="outline-orange"
              class="mr-2 text-sm text-black dark:text-white my-1"
              style="height: fit-content"
              >{{ $i18n.t("unmute") }}</t-button
            >
          </div>
        </div>

        <div class="mx-auto">
          <t-button
            @click="abandonRoom"
            class="mx-auto my-3"
            style="height: fit-content; cursor: pointer"
            >{{ $i18n.t("abandon_public_room") }}</t-button
          >
        </div>
      </div>
    </div>

    <t-modal
      :header="
        shortenName(
          room.type === 1
            ? `Near me - ${room.country[$i18n.locale + '_name']}`
            : room.name
        )
      "
      :value="enterUsernameModal"
      @closed="closeModal('enterUsernameModal')"
      variant="lg"
    >
      <div class="my-auto">
        <p class="text-black dark:text-white my-1">
          {{ $i18n.t("public_room_username_message_1") }}
        </p>

        <p class="text-black dark:text-white my-1">
          {{ $i18n.t("continue_without_username") }}
        </p>

        <t-input-group
          :feedback="errors.first('username') ? errors.first('username') : ''"
          :variant="errors.first('username') ? 'danger' : ''"
        >
          <t-input
            v-validate="'username'"
            data-vv-validate-on="input"
            name="username"
            v-model="username"
            :placeholder="$i18n.t('username')"
            autocomplete="off"
          >
          </t-input>
        </t-input-group>
      </div>

      <template v-slot:footer>
        <div class="flex justify-end">
          <t-button type="button" @click="joinPublicRoom" class="mx-1"
            >{{ $i18n.t("continue") }}
          </t-button>
        </div>
      </template>
    </t-modal>

    <t-modal
      header=""
      :value="sendFriendRequestModal"
      @closed="closeModal('sendFriendRequestModal')"
      variant="lg"
    >
      <div class="my-auto">
        <p class="text-black dark:text-white my-1 font-bold">
          {{ $i18n.t("send_friend_request_confirmation_1") }}
          <span class="text-orange-primary"
            >@{{ messageData ? messageData.username : "" }}</span
          >?
        </p>

        <p class="text-black dark:text-white my-1">
          {{ $i18n.t("send_friend_request_confirmation_2") }}
        </p>
      </div>

      <template v-slot:footer>
        <div class="flex justify-end">
          <t-button
            variant="gray"
            @click="closeModal('sendFriendRequestModal')"
            type="button"
            class="mx-1"
            >{{ $i18n.t("no") }}
          </t-button>
          <t-button type="button" @click="sendFriendRequest" class="mx-1"
            >{{ $i18n.t("yes") }}
          </t-button>
        </div>
      </template>
    </t-modal>
    <t-modal
      header=""
      :value="changeUsernameModal"
      @closed="closeModal('changeUsernameModal')"
      variant="lg"
    >
      <div class="my-auto">
        <t-input-group
          :feedback="errors.first('username') ? errors.first('username') : ''"
          :variant="errors.first('username') ? 'danger' : ''"
        >
          <t-input
            v-validate="'username'"
            data-vv-validate-on="input"
            name="username"
            v-model="username"
            :placeholder="$i18n.t('username')"
            autocomplete="off"
          >
          </t-input>
        </t-input-group>
      </div>

      <template v-slot:footer>
        <div class="flex justify-end">
          <t-button type="button" @click="updateUsername" class="mx-1"
            >{{ $i18n.t("update_username") }}
          </t-button>
        </div>
      </template>
    </t-modal>

    <t-modal
      header=""
      :value="banUserModal"
      @closed="closeModal('banUserModal')"
      variant="lg"
    >
      <div class="my-auto">
        <p class="text-black dark:text-white my-1 font-bold">
          {{ $i18n.t("ban_user_confirmation") }}
          <span class="text-orange-primary"
            >@{{ messageData ? messageData.username : "" }}</span
          >?
        </p>
      </div>

      <template v-slot:footer>
        <div class="flex justify-end">
          <t-button
            variant="gray"
            @click="closeModal('banUserModal')"
            type="button"
            class="mx-1"
            >{{ $i18n.t("no") }}
          </t-button>
          <t-button type="button" @click="banUser" class="mx-1"
            >{{ $i18n.t("yes") }}
          </t-button>
        </div>
      </template>
    </t-modal>

    <!-- <t-modal
      header=""
      :value="reportModal"
      @closed="closeModal('reportModal')"
      variant="lg"
    >
      <div style="max-height: 50vh; overflow-y: auto">
        <p class="text-black dark:text-white text-center text-sm">
          {{ $i18n.t("ban_votation_confirmation") }}
        </p>
        <div class="my-auto" v-if="reportReasons.length === 0">
          <p class="text-black dark:text-white text-center">
            {{ $i18n.t("no_elements") }}
          </p>
        </div>
        <div
          class="my-1 w-full py-3"
          v-for="reason in reportReasons"
          :key="'post-reason-' + reason.id"
          style="border-bottom: 1px solid #4a4a4a; cursor: pointer"
        >
          <div class="flex my-3 flex-wrap flex-col md:flex-row">
            <label class="flex justify-between items-center mx-1 my-1">
              <span class="text-black dark:text-white text-sm">{{
                reason[$i18n.locale + "_name"]
              }}</span>
            </label>
            <t-radio
              class="ml-auto mr-2 jevi-radio-input"
              :value="reason.id"
              v-model="reportReason"
              :disabled="isReportLoading"
            />
          </div>
        </div>
        <t-button
          @click="reportUser"
          class="mx-auto"
          style="height: fit-content"
          :disabled="!reportReason || isReportLoading"
        >
          <span v-if="!isReportLoading" class="text-white">{{
            $i18n.t("report")
          }}</span>

          <span v-if="isReportLoading">
            <t-loader
              v-if="isReportLoading"
              extraClass="mx-auto my-auto border-orange-primary" /></span
        ></t-button>
      </div>
    </t-modal>

    <t-modal
      header=""
      :value="banVotationModal"
      @closed="closeModal('banVotationModal')"
      variant="lg"
    >
      <div class="my-auto" v-if="userBanVotation">
        <p class="text-black dark:text-white my-2">
          {{ $i18n.t("new_ban_votation_1") }}
        </p>
        <p class="text-black dark:text-white my-1 font-bold">
          {{ $i18n.t("user") }}:
          <span class="text-orange-primary font-bold"
            >@{{ userBanVotation.user_public_room.username }}
            {{
              userBanVotation.followed_by_you_status === 2
                ? `(${userBanVotation.user_banned.username})`
                : ""
            }}</span
          >
        </p>
        <p class="text-black dark:text-white my-1">
          <span class="text-black dark:text-white font-bold"
            >{{ $i18n.t("reason") }}:</span
          >
          {{ userBanVotation.user_room_report[$i18n.locale + "_name"] }}
        </p>

        <p class="text-black dark:text-white mt-3">
          {{ $i18n.t("new_ban_votation_2") }}
        </p>
      </div>

      <template v-slot:footer>
        <div class="flex justify-end">
          <t-button
            :disabled="isVoteLoading"
            variant="gray"
            @click="voteForBan(0)"
            type="button"
            class="mx-1"
            >{{ $i18n.t("no") }}
          </t-button>
          <t-button
            :disabled="isVoteLoading"
            type="button"
            @click="voteForBan(1)"
            class="mx-1"
            >{{ $i18n.t("yes") }}
          </t-button>
        </div>
      </template>
    </t-modal> -->

    <t-modal
      header=""
      :value="forbiddenWordsModal"
      @closed="closeModal('forbiddenWordsModal')"
      :clickToClose="false"
      variant="lg"
    >
      <div class="my-auto">
        <p class="text-black dark:text-white my-1">
          {{ $i18n.t("sent_forbidden_words") }}
        </p>
      </div>

      <template v-slot:footer>
        <div class="flex justify-end">
          <t-button
            type="button"
            @click="forbiddenWordsModal = false"
            class="mx-1"
            >{{ $i18n.t("i_understand") }}
          </t-button>
        </div>
      </template>
    </t-modal>
  </div>
</template>

<script>
const notify = () => import("@/utils/notify.js")
import ProfilePicture from "@/components/user/ProfilePicture.vue"
import PublicMessage from "@/components/chat/PublicMessage.vue"

export default {
  components: {
    ProfilePicture,
    PublicMessage
  },
  props: {},
  computed: {
    random_chat() {
      return this.$store.getters["random_chat/getElement"]
    },
    room() {
      return this.$store.getters["public_room/getElement"]
    },
    theme() {
      return this.$store.getters["getTheme"]
    },
    loggedUser() {
      return this.$store.getters["auth/getUser"]
    },
    messages() {
      return this.$store.getters["public_room/getElementMessages"].filter(
        (message) =>
          !this.isOldAutomaticMessage(message) &&
          !message.blocked &&
          !this.mutedUsers.some(
            (x) =>
              x.id_user === message.id_user &&
              x.id_public_room === message.id_public_room
          )
      )
    },
    clearText() {
      return this.$store.getters["public_room/getClearText"]
    },
    mutedUsers() {
      return this.$store.getters["public_room/getMutedUsers"].filter(
        (x) => x.id_public_room === this.room.id
      )
    },
    forbiddenWordsCount() {
      return this.$store.getters["public_room/getForbiddenWordsCount"]
    },
    userPublicRoom() {
      return this.$store.getters["public_room/getUserPublicRoom"]
    },
    isBanned() {
      if (!this.userPublicRoom) return false
      if (new Date() < new Date(this.userPublicRoom.banned_until + "Z"))
        return true
      return false
    }

    /*     newUserBanVotation() {
      return this.$store.getters["public_room/getNewUserBanVotation"]
    },
    userBanVotation() {
      return this.$store.getters["public_room/getUserBanVotation"]
    },
    bannedUser() {
      return this.$store.getters["public_room/getBannedUser"]
    } */
  },
  data() {
    return {
      messagesLoading: this.room ? true : false,
      newMessage: "",
      newMessageLoader: false,
      showDetails: false,
      roomUsersAmount: 0,
      newFileLoader: false,
      enterUsernameModal: false,
      username: "",
      usernameSelected: false,
      sendFriendRequestModal: false,
      messageData: null,
      forbiddenWordsModal: false,
      changeUsernameModal: false,
      banUserModal: false
      /*    reportModal: false,
      reportReason: null,
      reportReasons: [],
      isReportLoading: false,
      banVotationModal: false,
      isVoteLoading: false, */
    }
  },
  methods: {
    shortenName(name) {
      return name.length > 30 ? name.split(0, 30) + "..." : name
    },
    banUser() {
      this.$socket.emit("force_ban_user", {
        id_user: this.loggedUser.id,
        id_user_banned: this.messageData.id_user,
        id_public_room: this.room.id
      })
      this.closeModal("banUserModal")
    },
    openBanUser(data) {
      this.messageData = data
      this.banUserModal = true
    },
    answerChatRequest(data) {
      this.$socket.emit("answer_chat_request", {
        ...data,
        id_public_room: this.room.id
      })
    },
    /*     voteForBan(option) {
      this.isVoteLoading = true
      this.$store
        .dispatch("global/create", {
          payload: {
            id_user: this.loggedUser.id,
            id_user_ban_votation: this.userBanVotation.id,
            vote: option
          },
          route: "/user_ban_vote/save",
          module: null
        })
        .then((response) => {
          this.isVoteLoading = false
          notify().then(({ notification }) => {
            notification(
              "ban_votation",
              response.code,
              this.$snotify,
              this.$i18n.locale
            )
          })
          this.closeModal("banVotationModal")
        })
        .catch((error) => {
          this.isVoteLoading = false
          notify().then(({ notification }) => {
            notification("error", error.code, this.$snotify, this.$i18n.locale)
          })
        })
    },
    reportUser() {
      if (!this.reportReason) {
        this.$snotify.error(
          this.$i18n.locale === "en"
            ? "Select a report reason"
            : "Selecciona un motivo para el reporte",
          this.$i18n.locale === "en" ? "Error" : "Error",
          {
            timeout: 5000,
            showProgressBar: true,
            closeOnClick: true,
            pauseOnHover: true,
            titleMaxLength: 40,
            bodyMaxLength: 100,
            position: "rightBottom"
          }
        )
      }
      this.isReportLoading = true
      this.$store
        .dispatch("global/create", {
          payload: {
            id_user_room_report: this.reportReason,
            id_public_room: this.room.id,
            id_user_banned: this.messageData.id_user
          },
          route: "/user_ban_votation/save",
          module: null
        })
        .then((response) => {
          this.isReportLoading = false
          if (response.code === 409) {
            notify().then(({ notification }) => {
              notification(
                "report_user",
                response.code,
                this.$snotify,
                this.$i18n.locale
              )
            })
          } else {
            notify().then(({ notification }) => {
              notification(
                "report",
                response.code,
                this.$snotify,
                this.$i18n.locale
              )
            })
          }
          this.closeModal("reportModal")
        })
        .catch((error) => {
          this.isReportLoading = false
          notify().then(({ notification }) => {
            notification("error", error.code, this.$snotify, this.$i18n.locale)
          })
        })
    },
    getReportingReasons() {
      this.$store
        .dispatch("global/getItems", {
          route: "/user_room_report/all",
          noPaginate: true,
          isMaster: true
        })
        .then((response) => {
          this.reportReasons = response.data
        })
        .catch((error) => {
          notify().then(({ notification }) => {
            notification("error", error.code, this.$snotify, this.$i18n.locale)
          })
        })
    },
    openMessageReport(data) {
      // TODO if this is enabled: Add @openMessageReport event handler to public-message
      this.messageData = data
      this.reportModal = true
    }, */
    startChat(message) {
      if (message.id_chat) {
        this.$socket.emit("leave_public_room", {
          id_user: this.loggedUser.id,
          id_public_room: this.room.id
        })
        this.$router.push("/messages?ch_id=" + message.id_chat)
      } else {
        this.$socket.emit("send_chat_request", {
          id_user_sent: this.loggedUser.id,
          id_user_received: message.id_user,
          id_public_room: this.room.id
        })
      }
    },
    isOldAutomaticMessage(message) {
      if (message.type_file !== -1) return false
      const messageDate = new Date(message.created_at + "Z")
      const dateDiff = new Date().getTime() - messageDate.getTime()
      if (dateDiff / 1000 >= 60.0) return true
      return false
    },
    updateUsername() {
      this.$socket.emit("change_username", {
        id_user: this.loggedUser.id,
        id_public_room: this.room.id,
        username: this.username
      })
      this.closeModal("changeUsernameModal")
    },
    toggleMute({ action, id_user, username }) {
      this.$store.dispatch("public_room/toggleUserMute", {
        action,
        id_user,
        username,
        id_public_room: this.room.id
      })
    },
    answerFriendRequest({ status_request, message }) {
      this.$socket.emit("answer_follow_request", {
        id_user_sent: message.id_user,
        id_user_received: this.loggedUser.id,
        id_public_room: this.room.id,
        status_request,
        id_message: message.id
      })
    },
    sendFriendRequest() {
      this.$socket.emit("send_follow_request", {
        id_user_sent: this.loggedUser.id,
        id_user_received: this.messageData.id_user,
        id_public_room: this.room.id
      })
      this.closeModal("sendFriendRequestModal")
    },
    openSendFriendRequestModal(data) {
      this.messageData = data
      this.sendFriendRequestModal = true
    },
    joinPublicRoom() {
      if (!this.userPublicRoom) {
        this.$socket.emit("join_public_room", {
          id_user: this.loggedUser.id,
          id_public_room: this.room.id,
          username:
            this.username !== "" ? this.username : this.loggedUser.username
        })
      } else {
        this.$socket.emit("join_public_room", {
          id_user: this.loggedUser.id,
          id_public_room: this.room.id,
          username: this.userPublicRoom.username
        })
      }
      this.usernameSelected = true
      this.closeModal("enterUsernameModal")
    },
    closeModal(modalName) {
      if (modalName === "enterUsernameModal") {
        this.username = ""
        if (!this.usernameSelected) this.joinPublicRoom()
      }
      if (modalName === "sendFriendRequestModal") {
        this.messageData = null
      }
      if (modalName === "reportModal") {
        this.messageData = null
        this.reportReason = null
      }
      if (modalName === "banVotationModal") {
        this.$store.dispatch("public_room/newBanVotation", {
          status: false,
          user_ban_votation: null
        })
      }
      if (modalName === "changeUsernameModal") {
        this.username = ""
      }
      if (modalName === "banUserModal") {
        this.messageData = null
      }
      this[modalName] = false
    },
    openPostReport(post) {
      this.$emit("openPostReport", post)
    },
    sharePost(post) {
      this.$emit("sharePost", post)
    },
    uploadFile(file) {
      let type_file = 4
      if (file.type.includes("image")) {
        type_file = 1
      } else if (file.type.includes("audio")) {
        type_file = 2
      }
      this.newFileLoader = true
      this.$store
        .dispatch("global/create", {
          payload: {
            id_user: this.loggedUser.id,
            id_public_room: this.room ? this.room.id : null,
            type_file: type_file,
            message: file.name,
            url_file: file
          },
          route: "/public_room_message/save",
          module: null
        })
        .then((response) => {
          this.$store.dispatch("public_room/clearText", true)
          this.newFileLoader = false
          this.$refs.chatUpload.onClosed()
        })
        .catch((error) => {
          notify().then(({ notification }) => {
            notification("error", error.code, this.$snotify, this.$i18n.locale)
          })
          this.newFileLoader = false
        })
    },
    abandonRoom() {
      this.$store
        .dispatch("global/changeStatus", {
          payload: { id: this.userPublicRoom.id, status: 2 },
          route: "/user_public_room"
        })
        .then((response) => {
          notify().then(({ notification }) => {
            notification(
              "abandon-community",
              response.code,
              this.$snotify,
              this.$i18n.locale
            )
          })
          if (response.code === 200) this.$emit("abandonedChat")
        })
        .catch((error) => {
          notify().then(({ notification }) => {
            notification("error", error.code, this.$snotify, this.$i18n.locale)
          })
        })
    },
    sendMessage() {
      if (this.newMessage.trim() === "")
        return this.$snotify.error(
          this.$i18n.locale === "en"
            ? "Message cannot be empty"
            : "El mensaje no puede estar vacío",
          "Error",
          {
            timeout: 5000,
            showProgressBar: true,
            closeOnClick: true,
            pauseOnHover: true,
            titleMaxLength: 40,
            bodyMaxLength: 100,
            position: "rightBottom"
          }
        )
      this.newMessageLoader = true
      if (this.room && this.room.id) {
        this.$socket.emit("message", {
          id_user: this.loggedUser.id,
          id_public_room: this.room ? this.room.id : null,
          message: this.newMessage
        })
        this.newMessageLoader = false
      } else {
        return this.$snotify.error(
          this.$i18n.locale === "en"
            ? "This community does not exist"
            : "Esta comunidad no existe",
          "Error",
          {
            timeout: 5000,
            showProgressBar: true,
            closeOnClick: true,
            pauseOnHover: true,
            titleMaxLength: 40,
            bodyMaxLength: 100,
            position: "rightBottom"
          }
        )
      }
    },
    getMessages() {
      this.messagesLoading = true
      this.$store
        .dispatch("global/getItems", {
          route: "/public_room_message/all",
          module: null,
          noPaginate: true,
          params: {
            id_public_room: this.room ? this.room.id : "",
            order_key: "updated_at",
            order_value: "desc",
            active: 1
          }
        })
        .then((response) => {
          let messages = response.data.data ? response.data.data : response.data
          messages = messages.reverse()
          this.$store.dispatch("public_room/setElementMessages", messages)
          this.messagesLoading = false
        })
        .catch((error) => {
          this.messagesLoading = false
          notify().then(({ notification }) => {
            notification("error", error.code, this.$snotify, this.$i18n.locale)
          })
        })
    },
    getRoomUsers() {
      this.$store
        .dispatch("global/getItems", {
          route: "/user_public_room/all",
          params: {
            id_public_room: this.room ? this.room.id : "",
            connected: 1,
            active: 1,
            page: 1,
            limit: 1
          }
        })
        .then((response) => {
          this.roomUsersAmount = response.data.meta.total
        })
        .catch((error) => {
          this.messagesLoading = false
          notify().then(({ notification }) => {
            notification("error", error.code, this.$snotify, this.$i18n.locale)
          })
        })
    },
    getLoggedRoomUser() {
      this.$store
        .dispatch("global/getItems", {
          route: "/user_public_room/all",
          noPaginate: true,
          params: {
            id_public_room: this.room ? this.room.id : "",
            id_user: this.loggedUser.id,
            active: 1
          }
        })
        .then((response) => {
          if (response.data.length) {
            this.$store.dispatch(
              "public_room/setUserPublicRoom",
              response.data[0]
            )
          } else {
            this.enterUsernameModal = true
          }
        })
        .catch((error) => {
          this.messagesLoading = false
          notify().then(({ notification }) => {
            notification("error", error.code, this.$snotify, this.$i18n.locale)
          })
        })
    }
  },
  created() {
    //this.getReportingReasons()
    this.$store.dispatch("public_room/clearText", false)
    if (!this.room) {
      this.$store.dispatch("public_room/setElementMessages", [])
    } else {
      this.getMessages()
      this.getRoomUsers()
      this.getLoggedRoomUser()
    }
  },
  watch: {
    userPublicRoom() {
      if (this.userPublicRoom) {
        //If user banned_until is still in the future, user is banned, else, user is not banned

        if (
          !this.userPublicRoom.banned_until ||
          new Date() > new Date(this.userPublicRoom.banned_until + "Z")
        )
          this.joinPublicRoom()
      }
    },
    forbiddenWordsCount() {
      if (this.forbiddenWordsCount) {
        this.forbiddenWordsModal = true
      }
    },
    room: {
      handler() {
        this.showDetails = false
        if (this.room) {
          this.getMessages()
          this.getRoomUsers()
          this.getLoggedRoomUser()
          this.newMessage = ""
        } else {
          this.$store.dispatch("public_room/setElementMessages", [])
        }
      },
      deep: true
    },
    messages() {
      if (this.room) {
        this.$nextTick(() => {
          if (this.$refs.chatbox) {
            const element = document.getElementById("chatbox")

            const scrolled = element.scrollTop + element.offsetHeight

            if (scrolled / element.scrollHeight > 0.9)
              this.$refs.chatbox.scrollTo(0, this.$refs.chatbox.scrollHeight)
          }

          if (this.$refs.roomNewMessage) this.$refs.roomNewMessage.focus()
        })
      }
    },
    clearText() {
      if (this.clearText) {
        this.newMessage = ""
        this.$store.dispatch("public_room/clearText", false)
      } else {
        this.$nextTick(() => {
          if (this.$refs.roomNewMessage) this.$refs.roomNewMessage.focus()
        })
      }
    },
    random_chat() {
      if (this.random_chat) {
        this.$socket.emit("leave_public_room", {
          id_user: this.loggedUser.id,
          id_public_room: this.room.id
        })
        this.$emit("startRandomChat")
      }
    }
  }
}
</script>
