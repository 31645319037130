<template>
  <div
    class="w-full"
    v-if="room"
    :key="room ? `station-room-${room.id}-${room.messages}` : 'new-room'"
  >
    <div class="flex w-full" :key="'room-' + room.id" ref="room-user-details">
      <div class="ml-3 w-full" :key="String(room.updated_at)">
        <p class="text-black dark:text-white text-sm sm:text-md font-bold">
          {{ $i18n.t("station_live_chat") }}
        </p>
        <p
          v-if="roomTopic"
          class="text-black dark:text-white text-sm sm:text-md font-bold"
        >
          {{ $i18n.t("current_subject") }}: {{ roomTopic }}
        </p>
      </div>
    </div>
    <div style="height: 70vh" class="relative flex flex-col content-between">
      <img
        v-if="messagesLoading"
        class="w-1/2 md:w-1/6 lg:w-1/5"
        :src="
          theme === 'dark'
            ? '/static/loading_darkmode.gif'
            : '/static/loading_lightmode.gif'
        "
        alt="loading-gif"
        style="
          z-index: 100;
          position: absolute;
          left: 50%;
          top: 50%;
          -webkit-transform: translateX(-50%) translateY(-50%);
          transform: translateX(-50%) translateY(-50%);
        "
      />
      <div
        v-if="!messagesLoading"
        style="height: 90%; overflow-y: auto"
        ref="chatbox"
        id="chatbox"
      >
        <message
          v-for="message in messages"
          :key="'message-' + message.id"
          :message="message"
        />
        <div
          v-if="messages.length === 0"
          class="flex items-center justify-center h-full"
        >
          <p class="text-black dark:text-white text-center my-auto">
            {{ $i18n.t("no_messages") }}
          </p>
        </div>
      </div>

      <t-input-group
        :feedback="errors.first('newMessage') ? errors.first('newMessage') : ''"
        :variant="errors.first('newMessage') ? 'danger' : ''"
        style="position: relative"
        v-if="!messagesLoading"
      >
        <t-input
          class="pr-10"
          name="newMessage"
          v-model="newMessage"
          :placeholder="$i18n.t('send_message')"
          autocomplete="off"
          ref="stationNewMessage"
          v-on:keyup.enter="sendMessage"
        >
        </t-input>
        <t-loader
          v-if="newMessageLoader"
          extraClass="text-2xl border-orange-primary"
          style="position: absolute; right: 2%; top: 30%; cursor: pointer"
        />
        <img
          v-if="!newMessageLoader"
          @click="sendMessage"
          src="/static/icons/send.svg"
          alt="Send icon"
          style="
            position: absolute;
            right: 2%;
            top: 30%;
            cursor: pointer;
            width: 21px;
            filter: invert(22%) sepia(80%) saturate(1684%) hue-rotate(326deg)
              brightness(95%) contrast(92%);
          "
        />
      </t-input-group>
    </div>
  </div>
  <div v-else>
    <p>{{ $i18n.t("not_listening_to_radio") }}</p>
  </div>
</template>

<script>
const notify = () => import("@/utils/notify.js")
import ProfilePicture from "@/components/user/ProfilePicture.vue"
import Message from "@/components/chat/Message.vue"

export default {
  components: {
    ProfilePicture,
    Message
  },
  props: {},
  computed: {
    room() {
      return this.$store.getters["stationRoom/getElement"]
    },
    roomTopic() {
      return this.$store.getters["stationRoom/getElementTopic"]
    },
    theme() {
      return this.$store.getters["getTheme"]
    },
    loggedUser() {
      return this.$store.getters["auth/getUser"]
    },
    messages() {
      return this.$store.getters["stationRoom/getElementMessages"]
    },
    clearText() {
      return this.$store.getters["stationRoom/getClearText"]
    }
  },
  data() {
    return {
      messagesLoading: this.room ? true : false,
      newMessage: "",
      newMessageLoader: false
    }
  },
  methods: {
    sendMessage() {
      if (this.newMessage.trim() === "")
        return this.$snotify.error(
          this.$i18n.locale === "en"
            ? "Message cannot be empty"
            : "El mensaje no puede estar vacío",
          "Error",
          {
            timeout: 5000,
            showProgressBar: true,
            closeOnClick: true,
            pauseOnHover: true,
            titleMaxLength: 40,
            bodyMaxLength: 100,
            position: "rightBottom"
          }
        )
      this.newMessageLoader = true
      if (this.room && this.room.id) {
        this.$socket.emit("message", {
          id_user: this.loggedUser.id,
          id_station_room: this.room ? this.room.id : null,
          message: this.newMessage
        })
        this.newMessageLoader = false
      } else {
        this.newMessageLoader = true
        this.$store
          .dispatch("global/create", {
            payload: {
              id_user: this.loggedUser.id,
              id_user_a: this.loggedUser.id,
              id_user_b: this.user.id,
              type_file: 0,
              message: this.newMessage
            },
            route: "/station_room_message/save",
            module: null
          })
          .then((response) => {
            this.$store.dispatch("stationRoom/addChatMessage", response.data)
            this.$store.dispatch("stationRoom/clearText", true)
            this.newMessageLoader = false
          })
          .catch((error) => {
            console.log("new room message error:", error)
            notify().then(({ notification }) => {
              notification(
                "error",
                error.code,
                this.$snotify,
                this.$i18n.locale
              )
            })
            this.newMessageLoader = false
          })
      }
    },
    getMessages() {
      this.messagesLoading = true
      this.$store
        .dispatch("global/getItems", {
          route: "/station_room_message/all",
          module: null,
          noPaginate: true,
          params: {
            id_station_room: this.room ? this.room.id : "",
            order_key: "updated_at",
            order_value: "desc"
          }
        })
        .then((response) => {
          let messages = response.data.data ? response.data.data : response.data
          messages = messages.reverse()
          this.$store.dispatch("stationRoom/setElementMessages", messages)
          this.messagesLoading = false
        })
        .catch((error) => {
          this.messagesLoading = false
          notify().then(({ notification }) => {
            notification("error", error.code, this.$snotify, this.$i18n.locale)
          })
        })
    }
  },
  created() {
    this.$store.dispatch("stationRoom/clearText", false)
    if (!this.room) {
      this.$store.dispatch("stationRoom/setElementMessages", [])
    } else {
      this.getMessages()
    }
  },
  watch: {
    room() {
      if (this.room) {
        this.getMessages()
      } else {
        this.$store.dispatch("stationRoom/setElementMessages", [])
      }
    },
    messages() {
      if (this.room) {
        this.$nextTick(() => {
          if (this.$refs.chatbox) {
            const element = document.getElementById("chatbox")

            const scrolled = element.scrollTop + element.offsetHeight

            if (scrolled / element.scrollHeight > 0.9)
              this.$refs.chatbox.scrollTo(0, this.$refs.chatbox.scrollHeight)
          }

          this.$refs.stationNewMessage.focus()
        })
      }
    },
    clearText() {
      if (this.clearText) {
        this.newMessage = ""
        this.$store.dispatch("stationRoom/clearText", false)
      } else {
        this.$nextTick(() => {
          this.$refs.stationNewMessage.focus()
        })
      }
    }
  }
}
</script>
