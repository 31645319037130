<template>
  <div
    class="w-full"
    v-if="room && !showDetails"
    :key="
      room
        ? `room-${room.id}-${
            room.last_message ? room.last_message.updated_at : ''
          }`
        : 'new-room'
    "
  >
    <div
      class="flex w-full"
      :key="'room-' + room.id"
      ref="room-user-details"
      style="cursor: pointer"
      @click="showDetails = true"
    >
      <div class="w-12 h-12">
        <profile-picture :data="room" :sizePx="96" />
      </div>

      <div class="ml-3 w-full" :key="String(room.updated_at)">
        <p class="text-black dark:text-white text-sm sm:text-md font-bold">
          {{ room.name }}
        </p>

        <div
          class="flex justify-between w-full"
          v-if="room.is_typing === 1 && room.user_writing"
        >
          <p class="text-gray-700 dark:text-gray-400 text-sm sm:text-md">
            {{ room.user_writing }} {{ $i18n.t("is_writing") }}...
          </p>
        </div>
        <div v-else>
          <p class="text-black dark:text-white text-sm sm:text-md font-bold">
            {{ room.description }}
          </p>
        </div>
      </div>
    </div>
    <div style="height: 70vh" class="relative flex flex-col content-between">
      <img
        v-if="messagesLoading"
        class="w-1/2 md:w-1/6 lg:w-1/5"
        :src="
          theme === 'dark'
            ? '/static/loading_darkmode.gif'
            : '/static/loading_lightmode.gif'
        "
        alt="loading-gif"
        style="
          z-index: 100;
          position: absolute;
          left: 50%;
          top: 50%;
          -webkit-transform: translateX(-50%) translateY(-50%);
          transform: translateX(-50%) translateY(-50%);
        "
      />
      <div
        v-if="!messagesLoading"
        style="height: 90%; overflow-y: auto"
        ref="chatbox"
        id="chatbox"
      >
        <message
          v-for="message in messages"
          :key="'message-' + message.id"
          :message="message"
          @openPostReport="openPostReport"
          @sharePost="sharePost"
        />
        <div
          v-if="messages.length === 0"
          class="flex items-center justify-center h-full"
        >
          <p class="text-black dark:text-white text-center my-auto">
            {{ $i18n.t("no_messages_in") }} {{ room.name }}
          </p>
        </div>
      </div>

      <t-input-group
        :feedback="errors.first('newMessage') ? errors.first('newMessage') : ''"
        :variant="errors.first('newMessage') ? 'danger' : ''"
        style="position: relative"
        v-if="!messagesLoading"
      >
        <t-input
          @click="markAsRead"
          class="pr-10"
          @keyup="isTypingDebounced"
          @keydown="stoppedTypingDebounced"
          name="newMessage"
          ref="roomNewMessage"
          v-model="newMessage"
          :placeholder="$i18n.t('send_message')"
          autocomplete="off"
          v-on:keyup.enter="sendMessage"
        >
        </t-input>
        <t-loader
          v-if="newFileLoader"
          extraClass="text-2xl border-orange-primary"
          style="position: absolute; right: 6%; top: 30%; cursor: pointer"
        />
        <chat-upload
          v-show="!newFileLoader"
          style="position: absolute; right: 4%; top: 5%"
          @upload="uploadFile"
          ref="chatUpload"
        />

        <t-loader
          v-if="newMessageLoader"
          extraClass="text-2xl border-orange-primary"
          style="position: absolute; right: 2%; top: 30%; cursor: pointer"
        />
        <img
          v-if="!newMessageLoader"
          @click="sendMessage"
          src="/static/icons/send.svg"
          alt="Send icon"
          style="
            position: absolute;
            right: 2%;
            top: 30%;
            cursor: pointer;
            width: 21px;
            filter: invert(78%) sepia(50%) saturate(4740%) hue-rotate(359deg)
              brightness(102%) contrast(108%);
          "
        />
      </t-input-group>
    </div>
  </div>
  <div v-else-if="showDetails" style="height: 85vh; overflow-y: auto">
    <div class="flex justify-start pt-3 pb-4">
      <t-button
        type="button"
        @click="showDetails = false"
        style="height: fit-content"
      >
        <icon class="mr-2" icon="angle-left" />{{ $i18n.t("return") }}</t-button
      >
    </div>

    <div class="px-3">
      <div class="w-1/3 mx-auto">
        <profile-picture :data="room" :sizePx="96" />
      </div>
      <div class="mx-auto my-4">
        <p
          class="text-center text-black dark:text-white text-md sm:text-xl font-bold"
        >
          {{ room.name }}
        </p>
        <p class="text-center text-black dark:text-white text-sm sm:text-md">
          {{ $i18n.t("members") }}: {{ roomUsers.length }}
        </p>
      </div>
      <div class="mx-auto">
        <p class="text-black dark:text-white text-sm sm:text-md">
          <strong>{{ $i18n.t("description") }}:</strong> {{ room.description }}
        </p>
        <div class="flex justify-between mt-3">
          <p class="text-black dark:text-white">
            {{ $i18n.t("notifications") }}
          </p>
          <t-toggle
            @click="toggleNotifications"
            v-model="notifications"
            :disabled="loadingNotifications"
          />
        </div>
        <t-button
          v-if="loggedUser.id === room.id_user_admin"
          @click="addUserModal = true"
          class="ml-auto my-3"
          style="height: fit-content; cursor: pointer"
          >{{ $i18n.t("add_user") }}</t-button
        >
        <p class="text-black dark:text-white text-sm sm:text-md my-5">
          {{ roomUsers.length }}
          <span
            class="text-black dark:text-white text-sm sm:text-md lowercase"
            >{{ $i18n.t("members") }}</span
          >
        </p>

        <div
          class="my-1 w-full"
          v-for="data in roomUsers"
          :key="'room-user-' + data.user.id"
          style="border-bottom: 1px solid #4a4a4a"
        >
          <div class="w-full flex justify-between">
            <div class="flex w-full">
              <div class="w-16 h-16">
                <profile-picture
                  v-if="data.user"
                  :data="data.user"
                  :sizePx="96"
                />
              </div>

              <div class="mx-3 w-full">
                <p class="text-black dark:text-white text-sm font-bold">
                  {{ data.user.first_name }} {{ data.user.last_name }}
                </p>
                <p class="text-black dark:text-white text-sm" style="display: flex; align-items: center;">
                  @{{ data.user.username }}
                  <span class="ml-1" v-if="data.user.verified == 1">
                    <img src="/static/user_verified.png" style="{ display: flex; height: 16px; width: 16px; }" />
                    <!--<font-awesome-icon class="mt-2 mb-0 my-0" icon="check-circle" style="color: #6a71ec; font-size: 16px" />-->
                  </span>
                </p>
              </div>
            </div>
            <t-button
              v-if="data.user.id === room.id_user_admin"
              variant="outline-orange"
              class="mr-2 text-sm text-black dark:text-white"
              style="height: fit-content; cursor: default"
              >{{ $i18n.t("admin") }}</t-button
            >
          </div>
        </div>

        <t-button
          @click="abandonCommunity"
          class="mx-auto my-3"
          style="height: fit-content; cursor: pointer"
          >{{ $i18n.t("abandon_community") }}</t-button
        >
      </div>
    </div>
    <template v-if="loggedUser.id === room.id_user_admin">
      <t-modal
        :header="$i18n.t('add_user')"
        :value="addUserModal"
        @closed="closeModal"
        variant="lg"
      >
        <div style="max-height: 50vh; overflow-y: auto">
          <div class="mx-auto text-center">
            <t-loader
              v-if="availableFriendsLoader"
              extraClass="border-orange-primary"
            />
          </div>
          <div class="my-auto" v-if="availableFriends.length === 0">
            <p class="text-black dark:text-white text-center">
              {{ $i18n.t("no_elements") }}
            </p>
          </div>
          <div
            class="my-1 w-full"
            v-for="(user, index) in availableFriends"
            :key="'user-' + user.id"
          >
            <div class="w-full flex justify-between items-center">
              <div class="flex w-2/3">
                <div class="w-16 h-16">
                  <profile-picture :data="user" :sizePx="96" />
                </div>
                <div class="ml-2">
                  <p class="text-black dark:text-white text-sm" style="display: flex; align-items: center;">
                    {{ user.username }}
                    <span class="ml-1" v-if="user.verified == 1">
                      <img src="/static/user_verified.png" style="{ display: flex; height: 16px; width: 16px; }" />
                      <!--<font-awesome-icon class="mt-2 mb-0 my-0" icon="check-circle" style="color: #6a71ec; font-size: 16px" />-->
                    </span>
                  </p>
                  <p class="text-black dark:text-white text-sm">
                    {{ `${user.first_name} ${user.last_name}` }}
                  </p>
                </div>
              </div>
              <div class="my-auto w-1/3">
                <t-button
                  @click="addUser(user, index)"
                  class="block w-full px-1 py-1 text-sm leading-5 text-black dark:text-white transition duration-150 ease-in-out bg-gray-100 dark:bg-gray-700 dark:hover:bg-gray-800 hover:bg-gray-200 focus:outline-none focus:bg-gray-200 dark:focus:bg-gray-800"
                  style="height: fit-content"
                  :disabled="addUserLoading === index"
                >
                  {{ $i18n.t("add") }}
                </t-button>
              </div>
            </div>
          </div>
        </div>
      </t-modal>
    </template>
  </div>
  <div v-else>
    <p>{{ $i18n.t("select_a_community") }}</p>
  </div>
</template>

<script>
const notify = () => import("@/utils/notify.js")
import ProfilePicture from "@/components/user/ProfilePicture.vue"
import Message from "@/components/chat/Message.vue"
import { debounce } from "lodash"

export default {
  components: {
    ProfilePicture,
    Message
  },
  props: {},
  computed: {
    room() {
      return this.$store.getters["room/getElement"]
    },
    theme() {
      return this.$store.getters["getTheme"]
    },
    loggedUser() {
      return this.$store.getters["auth/getUser"]
    },
    messages() {
      return this.$store.getters["room/getElementMessages"]
    },
    clearText() {
      return this.$store.getters["room/getClearText"]
    },
    user() {
      return this.$store.getters["room/getElementUser"]
    }
  },
  data() {
    return {
      messagesLoading: this.room ? true : false,
      newMessage: "",
      newMessageLoader: false,
      chat_user: {},
      showDetails: false,
      roomUsers: [],
      notifications: false,
      loadingNotifications: false,
      room_user: {},
      availableFriends: [],
      availableFriendsLoader: true,
      addUserModal: false,
      addUserLoading: null,
      newFileLoader: false
    }
  },
  methods: {
    openPostReport(post) {
      this.$emit("openPostReport", post)
    },
    sharePost(post) {
      this.$emit("sharePost", post)
    },
    uploadFile(file) {
      let type_file = 4
      if (file.type.includes("image")) {
        type_file = 1
      } else if (file.type.includes("audio")) {
        type_file = 2
      }
      this.newFileLoader = true
      this.$store
        .dispatch("global/create", {
          payload: {
            id_user: this.loggedUser.id,
            id_user_a: this.loggedUser.id,
            id_room: this.room ? this.room.id : null,
            type_file: type_file,
            message: file.name,
            url_file: file
          },
          route: "/room_message/save",
          module: null
        })
        .then((response) => {
          this.$store.dispatch("room/clearText", true)
          this.newFileLoader = false
          this.$refs.chatUpload.onClosed()
        })
        .catch((error) => {
          notify().then(({ notification }) => {
            notification("error", error.code, this.$snotify, this.$i18n.locale)
          })
          this.newFileLoader = false
        })
    },
    closeModal() {
      this.addUserModal = false
      this.addUserLoading = null
    },
    addUser(user, index) {
      this.addUserLoading = index
      this.$store
        .dispatch("global/create", {
          payload: {
            id_room: this.room.id,
            id_users: [user.id]
          },
          route: "/room_user/save",
          module: null
        })
        .then((response) => {
          this.addUserLoading = null
          notify().then(({ notification }) => {
            notification(
              "room_user",
              response.code,
              this.$snotify,
              this.$i18n.locale
            )
          })
          this.getFriends()
          this.getRoomUsers()
        })
        .catch((error) => {
          this.addUserLoading = null
          notify().then(({ notification }) => {
            notification("error", error.code, this.$snotify, this.$i18n.locale)
          })
        })
    },
    getFriends() {
      this.$store
        .dispatch("global/getItems", {
          route: "/follow_request/complete_list",
          module: null,
          noPaginate: true,
          params: {
            id_user: this.loggedUser.id,
            active: 1,
            id_room: this.room.id
          }
        })
        .then((response) => {
          this.availableFriendsLoader = false
          let users = response.data.data ? response.data.data : response.data
          users = users
            .filter((x) => x.belongs_to_group === 0)
            .map((x) => x.user)
          this.availableFriends = users
        })
        .catch((error) => {
          this.availableFriendsLoader = false
          notify().then(({ notification }) => {
            notification("error", error.code, this.$snotify, this.$i18n.locale)
          })
        })
    },
    abandonCommunity() {
      this.$store
        .dispatch("global/changeStatus", {
          payload: { id: this.room_user.id, status: 2 },
          route: "/room_user"
        })
        .then((response) => {
          notify().then(({ notification }) => {
            notification(
              "abandon-community",
              response.code,
              this.$snotify,
              this.$i18n.locale
            )
          })
          if (response.code === 200) this.$emit("abandonedChat")
        })
        .catch((error) => {
          notify().then(({ notification }) => {
            notification("error", error.code, this.$snotify, this.$i18n.locale)
          })
        })
    },
    toggleNotifications() {
      this.loadingNotifications = true
      let silenced

      if (this.notifications) {
        silenced = 1
      } else {
        silenced = 0
      }
      this.$store
        .dispatch("global/update", {
          payload: {
            id: this.room_user.id,
            silenced
          },
          route: "/room_user/update",
          module: null
        })
        .then((response) => {
          if (response.data.silenced === 1 || response.data.silenced === "1") {
            this.notifications = false
          } else {
            this.notifications = true
          }

          this.loadingNotifications = false
        })
        .catch((error) => {
          notify().then(({ notification }) => {
            this.loadingNotifications = false
            notification("error", error.code, this.$snotify, this.$i18n.locale)
          })
        })
    },
    isTyping() {
      if (this.room) {
        this.$socket.emit("typing", {
          id_user: this.loggedUser.id,
          id_room: this.room ? this.room.id : ""
        })
      }
    },
    isTypingDebounced() {},
    stoppedTyping() {
      if (this.room) {
        this.$socket.emit("stop_typing", {
          id_user: this.loggedUser.id,
          id_room: this.room ? this.room.id : ""
        })
      }
    },
    stoppedTypingDebounced() {},
    sendMessage() {
      if (this.newMessage.trim() === "")
        return this.$snotify.error(
          this.$i18n.locale === "en"
            ? "Message cannot be empty"
            : "El mensaje no puede estar vacío",
          "Error",
          {
            timeout: 5000,
            showProgressBar: true,
            closeOnClick: true,
            pauseOnHover: true,
            titleMaxLength: 40,
            bodyMaxLength: 100,
            position: "rightBottom"
          }
        )
      this.newMessageLoader = true
      if (this.room && this.room.id) {
        this.$socket.emit("message", {
          id_user: this.loggedUser.id,
          id_room: this.room ? this.room.id : null,
          message: this.newMessage
        })
        this.newMessageLoader = false
      } else {
        return this.$snotify.error(
          this.$i18n.locale === "en"
            ? "This community does not exist"
            : "Esta comunidad no existe",
          "Error",
          {
            timeout: 5000,
            showProgressBar: true,
            closeOnClick: true,
            pauseOnHover: true,
            titleMaxLength: 40,
            bodyMaxLength: 100,
            position: "rightBottom"
          }
        )
      }
    },
    markAsRead() {
      if (this.room) {
        this.$socket.emit("mark_as_read", {
          id_user: this.loggedUser.id,
          id_room: this.room.id
        })
      }
    },
    getMessages() {
      this.messagesLoading = true
      this.$store
        .dispatch("global/getItems", {
          route: "/room_message/all",
          module: null,
          noPaginate: true,
          params: {
            id_room: this.room ? this.room.id : "",
            order_key: "updated_at",
            order_value: "desc",
            active: 1
          }
        })
        .then((response) => {
          let messages = response.data.data ? response.data.data : response.data
          messages = messages.reverse()
          this.$store.dispatch("room/setElementMessages", messages)
          this.messagesLoading = false
        })
        .catch((error) => {
          this.messagesLoading = false
          notify().then(({ notification }) => {
            notification("error", error.code, this.$snotify, this.$i18n.locale)
          })
        })
    },
    getRoomUsers() {
      this.messagesLoading = true
      this.$store
        .dispatch("global/getItems", {
          route: "/room_user/all",
          module: null,
          noPaginate: true,
          params: {
            id_room: this.room ? this.room.id : "",
            active: 1
          }
        })
        .then((response) => {
          this.roomUsers = response.data
          this.room_user = this.roomUsers.find(
            (x) => x.id_user === this.loggedUser.id
          )
        })
        .catch((error) => {
          this.messagesLoading = false
          notify().then(({ notification }) => {
            notification("error", error.code, this.$snotify, this.$i18n.locale)
          })
        })
    },
    chatboxClicked(event) {
      var container = document.getElementById("chatbox")
      if (container) {
        if (container === event.target || container.contains(event.target)) {
          this.markAsRead()
        }
      }
    }
  },
  created() {
    this.$store.dispatch("room/clearText", false)
    if (!this.room) {
      this.$store.dispatch("room/setElementMessages", [])
    } else {
      this.getMessages()
      this.getRoomUsers()
      if (this.loggedUser.id === this.room.id_user_admin) this.getFriends()
      if (this.room.silenced === 0 || this.room.silenced === "0")
        this.notifications = true
    }
    window.vueComponent = this

    window.addEventListener("click", window.vueComponent.chatboxClicked)
    this.isTypingDebounced = debounce(this.isTyping, 1000, {
      leading: true,
      trailing: false
    })
    this.stoppedTypingDebounced = debounce(this.stoppedTyping, 1000)
  },
  beforeDestroy() {
    window.removeEventListener("click", window.vueComponent.chatboxClicked)
  },
  unmounted() {
    window.removeEventListener("click", window.vueComponent.chatboxClicked)
  },
  watch: {
    room() {
      if (this.room) {
        this.getMessages()
        this.getRoomUsers()
        this.newMessage = ""
        if (this.room.silenced === 0 || this.room.silenced === "0") {
          this.notifications = true
        } else {
          this.notifications = false
        }
      } else {
        this.$store.dispatch("room/setElementMessages", [])
      }
    },
    messages() {
      if (this.room) {
        this.$nextTick(() => {
          if (this.$refs.chatbox) {
            const element = document.getElementById("chatbox")

            const scrolled = element.scrollTop + element.offsetHeight

            if (scrolled / element.scrollHeight > 0.9)
              this.$refs.chatbox.scrollTo(0, this.$refs.chatbox.scrollHeight)
          }

          this.$refs.roomNewMessage.focus()
        })
      }
    },
    clearText() {
      if (this.clearText) {
        this.newMessage = ""
        this.$store.dispatch("room/clearText", false)
      } else {
        this.$nextTick(() => {
          this.$refs.roomNewMessage.focus()
        })
      }
    }
  }
}
</script>
